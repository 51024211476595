import React from 'react';
import donate from '../../assets/image/donate.webp'
import reg from '../../assets/image/registration.webp'
import phone from '../../assets/image/phone-call.webp'
import style from './BoxIcon.module.sass'
import {useNavigate} from "react-router";

const BoxIcon = () => {
    const navigate = useNavigate()
    return (
        <div className={style.boxIcon}>
            <a className={style.phone} href="tel:+74951093304"><img src={phone} alt="call"/></a>
            <img onClick={()=>navigate('/subscription')} src={reg} alt="registration"/>
            <img onClick={()=>navigate('/donate')} src={donate} alt="donate"/>
        </div>
    );
};

export default BoxIcon;
