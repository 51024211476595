import React from 'react';
import'./BurgerBtn.sass'
import {useDispatch} from "react-redux";
import {changeMobileMenu} from "../../Redux/Reducer/menuSlice";

const BurgerBtn = () => {
    const dispatch = useDispatch()
    return (
        <button
            aria-label={'Открыть меню'}
            className={'burger'}
            onClick={()=>dispatch(changeMobileMenu(true))}
        >
            <span/>
        </button>
    );
};

export default BurgerBtn;
