// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HonoraryCitizens_honoraryCitizens__5f6Kw {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.HonoraryCitizens_title__vKLqU {\n  text-align: center;\n  margin: 2.4305555556vw 0;\n  font-size: 1.6666666667vw;\n}\n@media (min-width: 321px) and (max-width: 768px) {\n  .HonoraryCitizens_title__vKLqU {\n    font-size: 20px;\n    margin: 25px 0;\n  }\n}\n@media (max-width: 320px) {\n  .HonoraryCitizens_title__vKLqU {\n    font-size: 18px;\n    margin: 20px 0;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/HonoraryCitizens/HonoraryCitizens.module.sass"],"names":[],"mappings":"AAMA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AALF;;AAMA;EACE,kBAAA;EACA,wBAAA;EACA,yBAAA;AAHF;AAIE;EAJF;IAKI,eAAA;IACA,cAAA;EADF;AACF;AACE;EAPF;IAQI,eAAA;IACA,cAAA;EAEF;AACF","sourcesContent":["@use 'sass:math'\n@function calcWidth($n)\n  @return $n*math.div(100vw,1440)\n@function calcHeight($n)\n  @return $n*math.div(100vh,1080)\n\n.honoraryCitizens\n  display: flex\n  flex-direction: column\n  align-items: center\n.title\n  text-align: center\n  margin: calcWidth(35) 0\n  font-size: calcWidth(24)\n  @media (min-width: 321px) and (max-width: 768px)\n    font-size: 20px\n    margin: 25px 0\n  @media (max-width: 320px)\n    font-size: 18px\n    margin: 20px 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"honoraryCitizens": "HonoraryCitizens_honoraryCitizens__5f6Kw",
	"title": "HonoraryCitizens_title__vKLqU"
};
export default ___CSS_LOADER_EXPORT___;
