import {Route, Routes} from "react-router";
import './App.sass';
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home/Home";
import News from "./pages/News/News";
import HistoryCreate from "./pages/HistoryCreate/HistoryCreate";
import AboutKrasnoyarsk from "./pages/AboutKrasnoyarsk/AboutKrasnoyarsk";
import President from "./pages/President/President";
import Presidium from "./pages/Presidium/Presidium";
import Club69 from "./pages/Club69/Club69"
import HonoraryCitizens from "./pages/HonoraryCitizens/HonoraryCitizens";
import DefendersOfMoscow from "./pages/DefendersOfMoscow/DefendersOfMoscow";
import DearOurVeterans from "./pages/DearOurVeterans/DearOurVeterans";
import WonderfulPeople from "./pages/WonderfulPeople/WonderfulPeople";
import NewsMore from "./components/NewsMore/NewsMore";
import Contacts from "./pages/Contacts/Contacts";
import Donate from "./pages/Donate/Donate";
import Media from "./pages/Media/Media";
import Privacy from "./pages/Privacy/Privacy";
import Subscription from "./pages/Subscription/Subscription";

function App() {
  return (
      <div className={'App'}>
        <Routes>
          <Route path='/' element={<Layout/>}>
            <Route index element={<Home/>}/>
             <Route path='history' element={<HistoryCreate/>}/>
             <Route path='news/' element={<News/>}/>
             <Route path='news/:id' element={<NewsMore/>}/>
             <Route path='about-us' element={<AboutKrasnoyarsk/>}/>
             <Route path='president' element={<President/>}/>
             <Route path='presidium' element={<Presidium/>}/>
             <Route path='69-club' element={<Club69/>}/>
             <Route path='honorary-citizens' element={<HonoraryCitizens/>}/>
             <Route path='defenders-of-Moscow' element={<DefendersOfMoscow/>}/>
             <Route path='dear-our-veterans' element={<DearOurVeterans/>}/>
             <Route path='wonderful-people' element={<WonderfulPeople/>}/>
             <Route path='contact' element={<Contacts/>}/>
             <Route path='donate' element={<Donate/>}/>
             <Route path='media' element={<Media/>}/>
             <Route path='privacy' element={<Privacy/>}/>
             <Route path='subscription' element={<Subscription/>}/>
          </Route>
        </Routes>
      </div>
  );
}

export default App;
