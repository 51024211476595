// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PresidiumList_presidiumList__GbS-h {\n  position: relative;\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: 1fr;\n  gap: 20px 20px;\n  max-width: 1200px;\n}\n@media (max-width: 1023px) and (min-width: 768px) {\n  .PresidiumList_presidiumList__GbS-h {\n    padding: 0 20px;\n  }\n}\n@media (max-width: 767px) and (min-width: 320px) {\n  .PresidiumList_presidiumList__GbS-h {\n    padding: 0 10px;\n  }\n}\n\n.PresidiumList_btnPresidium__YyJ3a {\n  cursor: pointer;\n  border: none;\n  background: transparent;\n  color: black;\n  font-size: 20px;\n  margin: 30px 0;\n}\n.PresidiumList_btnPresidium__YyJ3a:disabled {\n  color: rgba(0, 0, 0, 0.4);\n}", "",{"version":3,"sources":["webpack://./src/components/PresidiumList/PresidiumList.module.sass"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,aAAA;EACA,0BAAA;EACA,uBAAA;EACA,cAAA;EACA,iBAAA;AAAF;AACE;EAPF;IAQI,eAAA;EAEF;AACF;AAFE;EATF;IAUI,eAAA;EAKF;AACF;;AAJA;EACE,eAAA;EACA,YAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;AAOF;AANE;EACE,yBAAA;AAQJ","sourcesContent":["\n.presidiumList\n  position: relative\n  display: grid\n  grid-template-columns: 1fr\n  grid-template-rows: 1fr\n  gap: 20px 20px\n  max-width: 1200px\n  @media (max-width: 1023px) and (min-width: 768px)\n    padding: 0 20px\n  @media (max-width: 767px) and (min-width: 320px)\n    padding: 0 10px\n\n.btnPresidium\n  cursor: pointer\n  border: none\n  background: transparent\n  color: black\n  font-size: 20px\n  margin: 30px 0\n  &:disabled\n    color: rgba(0, 0, 0, 0.4)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"presidiumList": "PresidiumList_presidiumList__GbS-h",
	"btnPresidium": "PresidiumList_btnPresidium__YyJ3a"
};
export default ___CSS_LOADER_EXPORT___;
