import React from 'react';
import style from "./VeteransItem.module.sass";
import MedalsList from "../MedalsList/MedalsList";
import { v4 as uuidv4 } from 'uuid'
import PropTypes from "prop-types";


const VeteransItem = ({veteran}) => {
    const {name, birthday, subscription, rank, image, podvig, subscriptionImg, medals, rod_vojsk} = veteran.attributes
    const front = []
    const medal = []
    medals.data.forEach(item=>{
        item.attributes.category==="Медали "?medal.push(item):front.push(item)
    })
    return (
        <li className={style.container}>
            <div className={style.headerContainer}>
                <div className={style.cardMan}>
                    <h2>{name}</h2>
                    <p>Дата рождения {birthday.split('-').reverse().join('.')}</p>
                    {image.data?<img src={process.env.REACT_APP_URL+image.data.attributes.url} alt={name}/>:null}
                </div>
                <div className={style.rang}>
                    <img src={process.env.REACT_APP_URL+rod_vojsk.data.attributes.emblema.data.attributes.url} alt={rod_vojsk.data.attributes.name}/>
                    <div className={style.rangContainer}>
                        <div>
                            <span>Воинское звание:</span>
                            <p>{rank}</p>
                        </div>
                        <div>
                            <span>Род войск:</span>
                            <p>{rod_vojsk.data.attributes.name}</p>
                        </div>
                    </div>
                </div>
                <div className={style.rightBox}>
                    <p>{subscription}</p>
                    <img src={process.env.REACT_APP_URL+subscriptionImg.data.attributes.url} alt="Знамя"/>
                </div>
            </div>
            {podvig.data?
                <div className={style.podvig}>
                    <h3 className={style.subtitle}>Подвиг:</h3>
                    {podvig.data.map(elem=> <img key={uuidv4()} src={process.env.REACT_APP_URL+elem.attributes.url} alt={elem.attributes.name}/>)}
                </div>:null
            }
            {front?<MedalsList title={"Фронтовые заслуги отмечены государственными наградами"} medals={front}/>:null}
            {medal?<MedalsList title={"Медали"} medals={medal}/>:null}
        </li>
    );
};
VeteransItem.propsType = {
    veteran: PropTypes.object
}
export default VeteransItem;
