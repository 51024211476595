// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WonderfullPeople_container__i7xe4 {\n  max-width: 1200px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.WonderfullPeople_title__n8ULs {\n  font-size: 26px;\n  text-align: center;\n  margin: 30px 0;\n}\n\n.WonderfullPeople_subtitle__fzBb5 {\n  font-size: 20px;\n}\n\n.WonderfullPeople_people__jSo8l {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 20px;\n  padding: 20px;\n  border-bottom: 1px solid black;\n}\n.WonderfullPeople_people__jSo8l img {\n  padding: 20px;\n  max-width: 100%;\n  height: auto;\n}", "",{"version":3,"sources":["webpack://./src/pages/WonderfulPeople/WonderfullPeople.module.sass"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAAA;EACE,eAAA;EACA,kBAAA;EACA,cAAA;AAGF;;AAFA;EACE,eAAA;AAKF;;AAJA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,8BAAA;AAOF;AANE;EACE,aAAA;EACA,eAAA;EACA,YAAA;AAQJ","sourcesContent":[".container\n  max-width: 1200px\n  margin: 0 auto\n  display: flex\n  flex-direction: column\n  align-items: center\n.title\n  font-size: 26px\n  text-align: center\n  margin: 30px 0\n.subtitle\n  font-size: 20px\n.people\n  display: flex\n  flex-direction: column\n  align-items: center\n  margin-top: 20px\n  padding: 20px\n  border-bottom: 1px solid black\n  img\n    padding: 20px\n    max-width: 100%\n    height: auto\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WonderfullPeople_container__i7xe4",
	"title": "WonderfullPeople_title__n8ULs",
	"subtitle": "WonderfullPeople_subtitle__fzBb5",
	"people": "WonderfullPeople_people__jSo8l"
};
export default ___CSS_LOADER_EXPORT___;
