// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Error_container__sZTwD {\n  padding: 60px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.Error_container__sZTwD img {\n  height: auto;\n  max-width: 600px;\n}\n@media (max-width: 1023px) {\n  .Error_container__sZTwD img {\n    max-width: 300px;\n  }\n}\n.Error_container__sZTwD h2 {\n  font-family: \"Roboto-Bold\", sans-serif;\n  text-transform: uppercase;\n}\n@media (max-width: 1023px) {\n  .Error_container__sZTwD h2 {\n    font-size: 18px;\n  }\n}\n@media (max-width: 1023px) {\n  .Error_container__sZTwD {\n    padding: 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Error/Error.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,YAAA;EACA,gBAAA;AAEJ;AADI;EAHF;IAII,gBAAA;EAIJ;AACF;AAJE;EACE,sCAAA;EACA,yBAAA;AAMJ;AALI;EAHF;IAII,eAAA;EAQJ;AACF;AARE;EAhBF;IAiBI,aAAA;EAWF;AACF","sourcesContent":[".container\n  padding: 60px\n  display: flex\n  flex-direction: column\n  justify-content: center\n  align-items: center\n  img\n    height: auto\n    max-width: 600px\n    @media(max-width: 1023px)\n      max-width: 300px\n  h2\n    font-family: 'Roboto-Bold', sans-serif\n    text-transform: uppercase\n    @media(max-width: 1023px)\n      font-size: 18px\n  @media(max-width: 1023px)\n    padding: 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Error_container__sZTwD"
};
export default ___CSS_LOADER_EXPORT___;
