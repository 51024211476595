// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BoxIcon_boxIcon__TYyJf {\n  display: flex;\n  position: absolute;\n  right: 0;\n  padding: 0 30px;\n}\n.BoxIcon_boxIcon__TYyJf img {\n  cursor: pointer;\n  width: auto;\n  height: 30px;\n}\n@media (max-width: 1439px) {\n  .BoxIcon_boxIcon__TYyJf img {\n    height: 25px;\n  }\n}\n.BoxIcon_boxIcon__TYyJf img:nth-child(n+2) {\n  margin-left: 20px;\n}\n\n.BoxIcon_phone__928zb {\n  display: block;\n  margin: 0;\n}\n@media (max-width: 1023px) {\n  .BoxIcon_phone__928zb {\n    display: none;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/BoxIcon/BoxIcon.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,QAAA;EACA,eAAA;AACF;AAAE;EACE,eAAA;EACA,WAAA;EACA,YAAA;AAEJ;AADI;EAJF;IAKI,YAAA;EAIJ;AACF;AAJE;EACE,iBAAA;AAMJ;;AALA;EACE,cAAA;EACA,SAAA;AAQF;AAPE;EAHF;IAII,aAAA;EAUF;AACF","sourcesContent":[".boxIcon\n  display: flex\n  position: absolute\n  right: 0\n  padding: 0 30px\n  img\n    cursor: pointer\n    width: auto\n    height: 30px\n    @media(max-width: 1439px)\n      height: 25px\n  img:nth-child(n+2)\n    margin-left: 20px\n.phone\n  display: block\n  margin: 0\n  @media(max-width: 1023px)\n    display: none\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxIcon": "BoxIcon_boxIcon__TYyJf",
	"phone": "BoxIcon_phone__928zb"
};
export default ___CSS_LOADER_EXPORT___;
