// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HistoryCreate_container__JZT2y {\n  max-width: 1000px;\n  padding: 10px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n.HistoryCreate_container__JZT2y p {\n  text-indent: 20px;\n  font-size: 18px;\n}\n@media (max-width: 1023px) {\n  .HistoryCreate_container__JZT2y p {\n    font-size: 16px;\n  }\n}\n\n.HistoryCreate_title__ymHIA {\n  align-self: center;\n  margin: 20px 0;\n}\n@media (max-width: 1023px) {\n  .HistoryCreate_title__ymHIA {\n    font-size: 22px;\n  }\n}\n\n.HistoryCreate_img__ynQ\\+6 {\n  object-fit: cover;\n  margin: 10px 0;\n  height: auto;\n  max-width: 600px;\n  align-self: center;\n}\n@media (max-width: 1023px) {\n  .HistoryCreate_img__ynQ\\+6 {\n    max-width: 93.75vw;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/HistoryCreate/HistoryCreate.module.sass"],"names":[],"mappings":"AAIA;EACE,iBAAA;EACA,aAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAHF;AAIE;EACE,iBAAA;EACA,eAAA;AAFJ;AAGI;EAHF;IAII,eAAA;EAAJ;AACF;;AACA;EACE,kBAAA;EACA,cAAA;AAEF;AADE;EAHF;IAII,eAAA;EAIF;AACF;;AAJA;EACE,iBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAOF;AANE;EANF;IAOI,kBAAA;EASF;AACF","sourcesContent":["@use 'sass:math'\n@function calcWidth($n)\n  @return $n*math.div(100vw,320)\n\n.container\n  max-width: 1000px\n  padding: 10px\n  margin: 0 auto\n  display: flex\n  flex-direction: column\n  align-items: flex-start\n  p\n    text-indent: 20px\n    font-size: 18px\n    @media (max-width: 1023px)\n      font-size: 16px\n\n.title\n  align-self: center\n  margin: 20px 0\n  @media (max-width: 1023px)\n    font-size: 22px\n.img\n  object-fit: cover\n  margin: 10px 0\n  height: auto\n  max-width: 600px\n  align-self: center\n  @media (max-width: 1023px)\n    max-width: calcWidth(300)\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HistoryCreate_container__JZT2y",
	"title": "HistoryCreate_title__ymHIA",
	"img": "HistoryCreate_img__ynQ+6"
};
export default ___CSS_LOADER_EXPORT___;
