import React from 'react';
import error404 from '../../assets/image/404.webp'
import style from './Error.module.sass'
import PropTypes from "prop-types";

const Error = ({message}) => {
    return (
        <div className={style.container}>
            <img src={error404} alt="ERROR 404"/>
            <h2>{message}</h2>
        </div>
    );
};

Error.propsType = {
    message: PropTypes.string
}
export default Error;
