import React from 'react';
import president from '../../assets/image/history/288a7d29-fde0-4629-8744-af7fca0cd60c.webp'
import './President.sass'

const President = () => {
    return (
        <section className={'container presidentBox'}>
           <h1>Земляки, дорогие мои! </h1>
            <img className={'presidentImg'} src={president} alt="president"/>
            <p>Любовь к Родине начинается с родного края. И как бы ни сложилась жизнь, именно родные места навсегда связывают человека с любимой землей. Очень важно, что есть Красноярское землячество, это объединение людей с одной земли из славного  прекрасного сибирского Красноярского края.  Нас роднит малая Родина.
            </p>
            <br/>
            <p>Кто-то родился в крае, а кто-то связал свою судьбу с краем, приехав на работу. В любом случае земляки оставили часть своей души там, на сибирской земле. Спасибо всем, кто помогал и помогает налаживать связь с  бывшими коллегами, кто делает добрые дела для земляков и для нашего землячества, для Красноярского края и оказывает помощь г. Свердловску ЛНР,  над которым закреплено шефство Красноярского края.
                Дорогие друзья, огромное сибирское СПАСИБО  за поддержку в моём избрании и надеюсь на наше дальнейшее тесное сотрудничество! Я сделаю всё от меня зависящее в нашем добром деле, обещаю.  Желаю вам самых щедрых, самых прекрасных жизненных подарков, мирного неба, и пусть все задуманное сбывается, а в ваших домах царит любовь и благополучие!
            </p>
            <br/>
            <br/>
            <p style={{textIndent: '0px'}}>Председатель  РОО-Общество «Красноярское землячество» <br/>Ваш <span style={{fontFamily:'Roboto-Bold'}}>Сергей Ерёмин</span></p>
        </section>
    );
};

export default President;
