import React from 'react';
import style from './MedalsItem.module.sass'
import PropTypes from "prop-types";

const MedalsItem = ({medals}) => {
    const {image, name} = medals.attributes
    return (
        <li className={style.slugItem}>
            <img src={process.env.REACT_APP_URL+image.data.attributes.url} alt="орден"/>
            <p>{name}</p>
        </li>
    );
};
MedalsItem.propTypes={
    medals: PropTypes.object
}
export default MedalsItem;
