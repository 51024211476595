// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Club69_club69__W9mfX {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.Club69_imgClub__TmpLy {\n  max-width: 400px;\n  height: auto;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/Club69/Club69.module.sass"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;EACA,aAAA;AACF;;AAAA;EACE,gBAAA;EACA,YAAA;EACA,WAAA;AAGF","sourcesContent":[".club69\n  max-width: 1200px\n  margin: 0 auto\n  padding: 20px\n.imgClub\n  max-width: 400px\n  height: auto\n  width: 100%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"club69": "Club69_club69__W9mfX",
	"imgClub": "Club69_imgClub__TmpLy"
};
export default ___CSS_LOADER_EXPORT___;
