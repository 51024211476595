import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import VeteransItem from "../VeteransItem/VeteransItem";
import Preloader from "../Preloader/Preloader";
import {fetchVeterans, incPaginationVeterans} from "../../Redux/Reducer/veteransSlice";
import Error from "../Error/Error";
import ScrollBtn from "../ScrollBtn/ScrollBtn";
import style from './VeteransList.module.sass'
import { v4 as uuidv4 } from 'uuid'

const VeteransList = () => {
    const dispatch = useDispatch()
    const {status, error, serverEmpty, pagination, content} = useSelector(state=>state.veterans)
    useEffect(()=>{
        dispatch(fetchVeterans({pagination}))
    },[dispatch,pagination])
    if(status==='loading'){
        return <Preloader/>
    }
    if(status==='rejected'){
        return <Error message={error}/>
    }
    if(status==='loaded'){
        return (
            <div className={style.container}>
                <ul id={'veteransList'}>
                    {content.map(item=><VeteransItem key={uuidv4()} veteran={item}/>)}
                </ul>
                <button
                    className={style.btnMore}
                    disabled={serverEmpty}
                    onClick={()=>{
                        dispatch(incPaginationVeterans())
                    }}
                >Ещё...</button>
                <ScrollBtn/>
            </div>
        )
    }

};

export default VeteransList;
