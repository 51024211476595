// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VeteransList_container__to18M {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.VeteransList_btnMore__7Z6ni {\n  cursor: pointer;\n  font-size: 20px;\n  padding: 20px;\n  font-family: \"Roboto-Medium\", sans-serif;\n  background: transparent;\n  outline: none;\n  border: none;\n}", "",{"version":3,"sources":["webpack://./src/components/VeteransList/VeteransList.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAAA;EACE,eAAA;EACA,eAAA;EACA,aAAA;EACA,wCAAA;EACA,uBAAA;EACA,aAAA;EACA,YAAA;AAGF","sourcesContent":[".container\n  display: flex\n  flex-direction: column\n  align-items: center\n.btnMore\n  cursor: pointer\n  font-size: 20px\n  padding: 20px\n  font-family: 'Roboto-Medium', sans-serif\n  background: transparent\n  outline: none\n  border: none\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "VeteransList_container__to18M",
	"btnMore": "VeteransList_btnMore__7Z6ni"
};
export default ___CSS_LOADER_EXPORT___;
