import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const fetchPresidium = createAsyncThunk(
    'presidium/fetchPresidium',
    async ({pagination}, {rejectedWithValue})=>{
        try {
            const response = await fetch(process.env.REACT_APP_URL+`/api/presidiums?sort[0]=raiting_pravleniya.id&populate[image][fields]=url&pagination[start]=${pagination}&pagination[limit]=4`)
            if (!response.ok){
                return rejectedWithValue('Страница не найдена')
            }
            return await response.json()
        }
        catch (error){
            return rejectedWithValue(error.message)
        }
    }
)

const presidiumSlice = createSlice({
    name: 'presidium',
    initialState: {
        status: 'load',
        error: null,
        serverEmpty: false,
        pagination: 0,
        content: [],
    },
    reducers: {
        incPagination(state){
            state.pagination = state.pagination+4
        }
    },
    extraReducers: {
        [fetchPresidium.pending]:(state)=>{
            if(!state.content.length){
                state.status='loading'
            }
            state.error = null
        },
        [fetchPresidium.fulfilled]:(state, action)=>{
            const {data, meta} = action.payload
            const totalPresidiumServer = meta.pagination.total
            state.status='loaded'
            state.serverEmpty = false;
            if (data.length===4&&data.length){
                const arr2 = []
                data.forEach(elem=>{
                    const index = state.content.findIndex(i=>
                        elem.id===i.id
                    )
                    if(index===-1){
                        arr2.push(elem)
                    }
                })
                state.content = [...state.content, ...arr2]
            }
            if(data.length<4&&data.length){
                const arr3 = []
                data.forEach(elem=>{
                    const index = state.content.findIndex(i=>
                        elem.id===i.id
                    )
                    if(index===-1){
                        arr3.push(elem)
                    }
                })
                state.content = [...state.content, ...arr3]
                state.serverEmpty = true
            }
            if(!data.length){
                state.serverEmpty = true
            }
            if(state.pagination+4===totalPresidiumServer){
                state.serverEmpty = true
            }
        },
        [fetchPresidium.rejected]:(state, action)=>{
            state.status = 'rejected'
            console.log(action)
        }

    }
})
export const {incPagination} = presidiumSlice.actions
export default presidiumSlice.reducer;
