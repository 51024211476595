import {createSlice} from "@reduxjs/toolkit";

const menuSlice = createSlice({
    name: 'menu',
    initialState: {
       mobileMenu: false,
       activeMenu: 'start',
       subMenuActive: false
    },
    reducers:{
        subMenuActive(state, action){
           state.subMenuActive=action.payload
        },
        changeMobileMenu(state, action){
            state.mobileMenu = action.payload
        },
        changeActiveMenu(state, action){
            state.activeMenu = action.payload
        },

    }
})

export default menuSlice.reducer;
export const {changeMenu, changeActiveMenu, changeMobileMenu} = menuSlice.actions;
