import {combineReducers} from "redux";
import menuSlice from "./menuSlice";
import newsSlice from "./newsSlice";
import presidiumSlice from "./presidiumSlice";
import honoraryCitizensSlice from "./honoraryCitizensSlice";
import veteransSlice from "./veteransSlice";
import newsMoreSlice from "./newsMoreSlice";
import subscriptionSlice from "./subscriptionSlice";


const rootReducer = combineReducers({
    menu: menuSlice,
    news: newsSlice,
    presidium: presidiumSlice,
    honoraryCitizen: honoraryCitizensSlice,
    veterans: veteransSlice,
    newsMore: newsMoreSlice,
    subscription: subscriptionSlice
})

export default rootReducer;
