import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Preloader from "../Preloader/Preloader";
import Error from "../Error/Error";
import {fetchNews, incPaginationFirst} from "../../Redux/Reducer/newsSlice";
import NewsItem from "../NewsItem/NewsItem";
import { v4 as uuidv4 } from 'uuid';
import style from './NewsList.module.sass'
import PropTypes from "prop-types";

const NewsList = ({count, section}) => {
    const dispatch = useDispatch()
    const {news, pagination, status, error}=useSelector(state=>state.news)
    const path = `/api/news?sort[0]=date%3Adesc&pagination[start]=${pagination}&pagination[limit]=4&fields[0]=title&fields[1]=date&populate[images][fields][0]=formats`
    useEffect(()=>{
        if(pagination===0){
            dispatch(fetchNews({path}))
            dispatch(incPaginationFirst())
        }
        })
    if(status==='loading'){
        return <Preloader/>
    }
    if (status==='loaded'&&count===2)
    return (
        <ul className={section==='home'?style.newsListHome:style.newsList}>
            {news.map((elem, index)=>{
                const {attributes} = elem
                if(index<2){
                   return <NewsItem id={elem.id} key={uuidv4()} content={attributes}/>
                }
                return null
            })}
        </ul>
    );
    if (status==='loaded'&&count!==2)
        return (
            <ul className={section==='home'?style.newsListHome:style.newsList}>
                {news.map((elem, index)=>{
                    const {attributes} = elem
                    return <NewsItem id={elem.id} key={uuidv4()} content={attributes}/>
                })}
            </ul>
        );
    if(status==='rejected'){
        return <Error message={error}/>
    }
};

NewsList.propsType = {
    count: PropTypes.number,
    section: PropTypes.string
}
export default NewsList;
