// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutKrasnoyarsk_title__u9hXI {\n  font-size: 30px;\n  text-align: center;\n  margin: 30px 0;\n}\n\n.AboutKrasnoyarsk_container__KEF9Q {\n  max-width: 1200px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/AboutKrasnoyarsk/AboutKrasnoyarsk.module.sass"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,cAAA;AACF;;AAAA;EACE,iBAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAGF","sourcesContent":[".title\n  font-size: 30px\n  text-align: center\n  margin: 30px 0\n.container\n  max-width: 1200px\n  margin: 0 auto\n  display: flex\n  flex-direction: column\n  align-items: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "AboutKrasnoyarsk_title__u9hXI",
	"container": "AboutKrasnoyarsk_container__KEF9Q"
};
export default ___CSS_LOADER_EXPORT___;
