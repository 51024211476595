import React from 'react';
import style from './News.module.sass'
import NewsList from "../../components/NewsList/NewsList";
import {useDispatch, useSelector} from "react-redux";
import {fetchNews, incPagination} from "../../Redux/Reducer/newsSlice";
import ScrollBtn from "../../components/ScrollBtn/ScrollBtn";

const News = () => {
    const dispatch = useDispatch()
    const {pagination, serverEmpty} = useSelector(state=>state.news)
    const path = `/api/news?sort[0]=date%3Adesc&pagination[start]=${pagination}&pagination[limit]=4&fields[0]=title&fields[1]=date&populate[images][fields][0]=formats`
    const loadNews = ()=>{
        dispatch(incPagination())
        dispatch(fetchNews({path}))
    }

    return (
        <section className={style.newsBox}>
            <h1 className={style.title}>Новости</h1>
            <NewsList count={4}/>
            <button
                disabled={serverEmpty}
                onClick={loadNews} className={style.span}>Ещё...</button>
            <ScrollBtn/>
        </section>
    );
};

export default News;
