import React, {useEffect} from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import Preloader from "../Preloader/Preloader";
import Error from "../Error/Error";
import {useDispatch, useSelector} from "react-redux";
import {fetchNewsMore} from "../../Redux/Reducer/newsMoreSlice";
import {useParams} from "react-router";
import { v4 as uuidv4 } from 'uuid';
import style from './NewsMore.module.sass'
import ScrollBtn from "../ScrollBtn/ScrollBtn";
import 'swiper/scss/navigation';
import {Navigation} from "swiper";


const NewsMore = () => {
    const {status, news, error} = useSelector(state=>state.newsMore)
    const {id} = useParams()
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(fetchNewsMore({id}))
    },[dispatch, id])
    window.scrollTo({
        left:0,
        top: 0
    })
    if(status==='loading'){
        return <Preloader/>
    }
    if(status==='rejected'){
        return <Error message={error}/>
    }
    if(status==='loaded'){
        const {title, content, date, images} = news.data.attributes
        const arrContent = content.split('\n').filter(item=>item!=='')
        return (
            <section className={style.newsMore}>
                <div className={style.boxTitle}>
                    <h1 className={style.title}>{title}</h1>
                    <span>{date.split('-').reverse().join('.')}</span>
                </div>
                {images.data?
                    <div className={style.slider}>
                        <Swiper
                            modules={[Navigation]}
                            loop={true}
                            navigation={true}
                        >
                            {images.data.map(item=>
                                <SwiperSlide key={uuidv4()}>
                                    <img className={style.imgSlide} src={process.env.REACT_APP_URL+item.attributes.url} alt={process.env.REACT_APP_URL+item.attributes.name}/>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>:null
                }
                <div className={style.content}>
                    {arrContent.map((text, index)=>{
                        return(
                            <div key={index}>
                                <br/>
                                <p>{text}</p>
                            </div>
                        )
                    })}
                </div>
                <ScrollBtn/>
            </section>
        )
    }
};

export default NewsMore;
