import React from 'react';
import PresidiumList from "../../components/PresidiumList/PresidiumList";
import style from './Presidium.module.sass'

const Presidium = () => {

    return (
        <section className={style.presidium}>
            <h2 className={style.title}>Состав правления РОО-Общество <br/>&laquo;Красноярское землячество&raquo;</h2>
            <PresidiumList/>
        </section>
    );
};

export default Presidium;
