import React, {useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {motion} from "framer-motion";
import {changeActiveMenu, changeMobileMenu} from "../../Redux/Reducer/menuSlice";
import MenuList from "../MenuList/MenuList";


const NavigationMenu = () => {

    const menuList = [
        {
            title: 'Главная',
            link: '/'
        },
        {
            title: 'Новости',
            link: '/news'
        },
        {
            title: 'Землячество',
            subMenu: {
                id: 'community',
                submenuList: [
                    {
                        title: 'История создания',
                        link: '/history'
                    },
                    {
                        title: 'Председатель землячества',
                        link: '/president'
                    },
                    {
                        title: 'Правление',
                        link: '/presidium'
                    },
                    {
                        title: 'О Красноярском крае',
                        link: '/about-us'
                    },
                ]
            }
        },
        {
            title: 'Клубы',
            subMenu: {
                id: 'clubs',
                submenuList: [
                    {
                        title: 'Клуб «69-я ПАРАЛЛЕЛЬ»',
                        link: '/69-club'
                    },

                ]
            }
        },
        {
            title: 'Дела и люди',
            subMenu: {
                id: 'worksPeople',
                submenuList: [
                    {
                        title: 'Почетные граждане Красноярского края',
                        link: '/honorary-citizens'
                    },
                    {
                        title: 'Сибирякам-защитникам Москвы',
                        link: '/defenders-of-Moscow'
                    },
                    {
                        title: 'Дорогие наши ветераны',
                        link: '/dear-our-veterans'
                    },
                    {
                        title: 'Жизнь замечательных Людей',
                        link: '/wonderful-people'
                    },

                ]
            }
        },
        {
            title: 'Поддержка',
            link: '/donate'
        },
        {
            title: 'Контакты',
            link: '/contact'
        },
        {
            title: 'Медиатека',
            link: '/media'
        },
    ]

    const {mobileMenu} = useSelector(state=>state.menu)
    const dispatch = useDispatch()
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, size);
    const checkOverlay = ()=>{
        if(size[0]>1023||(size[0]<1023&&!mobileMenu)){
            return false
        }
        if(size[0]<1023&&mobileMenu){
            return true
        }
    }
    const checkMobile = ()=>{
        if(size[0]>1023){
            return true
        }
        return checkOverlay()
    }
    const menuStart = ()=> {
        dispatch(changeActiveMenu('start'))
    }
    return(
        <>
            <motion.div
                className="overlay"
                onClick={()=>{
                    menuStart()
                    dispatch(changeMobileMenu(false));
                }}
                initial={false}
                animate={checkOverlay()?'open':'closed'}
                trnsition={{
                    duration: 2
                }}
                variants={{
                    open: {
                        opacity: 1,
                        visibility: "visible"
                    },
                    closed: {
                        opacity: 0,
                        visibility: "hidden"
                    }
                }}
            />
            <motion.nav
                className={'navMenu'}
                initial={false}
                animate={checkMobile()?'open':'closed'}
                variants={{
                    open: {
                        translateX: '0vw'
                    },
                    closed: {
                        translateX: '-150vw'
                    }
                }}
            >
                <MenuList listMenu={menuList}/>
            </motion.nav>
        </>

    )
};
export default NavigationMenu;
