import {createSlice,createAsyncThunk} from "@reduxjs/toolkit"
export const fetchNews = createAsyncThunk(
    'news/fetchNews',
    async ({path}, {rejectedWithValue})=>{
        try{
            const response = await fetch(process.env.REACT_APP_URL+path)
            if(!response.ok){
                return rejectedWithValue('Страница не найдена')
            }
            return await response.json()
        }
        catch (error){
            return  rejectedWithValue(error.message)
        }
    }
)

const newsSlice = createSlice({
    name: 'news',
    initialState: {
        status: 'load',
        error: null,
        serverEmpty: false,
        pagination: 0,
        news: [],
    },
    reducers:{
        incPagination(state){
            state.pagination = state.pagination+4
        },
        incPaginationFirst(state){
            state.pagination = 4
        }
    },
    extraReducers:{
        [fetchNews.pending]:(state)=>{
            if(!state.news.length){
                state.status='loading'
            }
            state.error=null
        },
        [fetchNews.fulfilled]:(state, action)=>{
            const {data, meta} = action.payload
            const totalNewsServer = meta.pagination.total
            state.status='loaded'
            state.serverEmpty = false;
            if (data.length===4&&data.length){
                const arr2 = []
                data.forEach(elem=>{
                    const index = state.news.findIndex(i=>
                        elem.id===i.id
                    )
                    if(index===-1){
                        arr2.push(elem)
                    }
                })
                state.news = [...state.news, ...arr2]
            }
            if(data.length<4&&data.length){
                const arr3 = []
                data.forEach(elem=>{
                    const index = state.news.findIndex(i=>
                        elem.id===i.id
                    )
                    if(index===-1){
                        arr3.push(elem)
                    }
                })
                state.news = [...state.news, ...arr3]
                state.serverEmpty = true
            }
            if(!data.length){
                state.serverEmpty = true
            }
            if(state.pagination===totalNewsServer){
                state.serverEmpty = true
            }
        },
        [fetchNews.rejected]:(state, action)=>{
            state.status = 'rejected'
            state.error = action.payload
        }

    }
})

export const {incPagination, incPaginationFirst} = newsSlice.actions
export default newsSlice.reducer;
