import React from 'react';
import {Outlet, useNavigate} from "react-router";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import style from './Layout.module.sass'
import BoxIcon from "../BoxIcon/BoxIcon";
import BurgerBtn from "../BurgerBtn/BurgerBtn";
import coat from '../../assets/image/gerb_krasnoyarskogo_kraya.webp'

const Layout = () => {
    const navigation = useNavigate()

    return (
        <>
            <header className={style.header}>
                <BurgerBtn/>
                <a className={style.callText} href="tel:+74951093304">8 (495) 109-33-04</a>
                <NavigationMenu/>
                <BoxIcon/>
            </header>
            <main className={style.main}>
                <Outlet/>
            </main>
            <a style={{textAlign: 'center', fontSize: '14px', margin:'30px 0'}} href="https://gnkk.ru/">Новости Красноярска на сайте «Наш Красноярский край»</a>
            <footer className={style.footer}>
                <div className={style.container}>
                    <img onClick={()=>navigation('/')} src={coat} alt="coat of arms"/>
                    <h3>Региональная общественная организация – Общество «Красноярское землячество»</h3>
                    <div className={style.footerBox}>
                        <h4>Адрес</h4>
                        <p>107031, г.Москва, Нижний Кисельный переулок, д.10</p>
                    </div>
                    <div className={style.footerBox}>
                        <h4>Контакты</h4>
                        <a href="mailto:kraszeml24@mail.ru">Email: kraszeml24@mail.ru</a>
                        <a href="tel:+74951093304">Телефон: 8 (495) 109-33-04</a>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Layout;
