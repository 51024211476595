import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types'
import style from './ButtonBlue.module.sass'
import {motion} from "framer-motion";
const ButtonBlue = ({specStyle,link, title}) => {
    return (
        <motion.button
            style={specStyle}
            className={style.blueBtn}
            whileHover={{scale: 1.1}}
            whileTap={{scale: 0.9}}
        >
            <Link to={link}>{title}</Link>
        </motion.button>
    );
};
ButtonBlue.propTypes={
    link: PropTypes.string,
    title: PropTypes.string,
    specStyle: PropTypes.object
}
export default ButtonBlue;
