import React from 'react';
import {Link} from "react-router-dom";
import style from './Form.module.sass'
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {changeForm, clearForm, fetchSubscription} from "../../Redux/Reducer/subscriptionSlice";
import Preloader from "../Preloader/Preloader";
import Error from "../Error/Error";
import {useNavigate} from "react-router";
import Thanks from "../Thanks/Thanks";



const Form = () => {
    const navigation = useNavigate()
    const {status, error, name, surname, patronymic, email} = useSelector(state=>state.subscription)
    const dispatch = useDispatch();
    const handleFormInput=(e)=>{
        dispatch(changeForm({
            input: e.target.name,
            value: e.target.value
        }))
    }
    const sendForm = (e)=>{
        e.preventDefault()
       const body = JSON.stringify({data: {
               name, surname,patronymic,email
           }})
       dispatch(fetchSubscription({body}))
    }
    if(status=== 'loading'){
        return <Preloader/>
    }
    if(status==='rejected'){
        return (
            <>
                <Error message={error}/>
                <button onClick={()=>dispatch(clearForm())}  className={style.again}>Попробовать снова</button>
                <button onClick={()=>navigation('/')}  className={style.again}>На главную</button>
            </>)
    }
    if (status==='loaded'){
        return (
                <Thanks/>
            )
    }
    if(status ==='load'){
        return (
            <form onSubmit={sendForm} className={style.form}>
                <label className={style.label}>
                    <span>Фамилия</span>
                    <input onChange={handleFormInput} value={surname} name={'surname'} required className={style.input} type="text"/>
                </label>
                <label className={style.label}>
                    <span>Имя</span>
                    <input onChange={handleFormInput} value={name} name={'name'} required className={style.input} type="text"/>
                </label>
                <label className={style.label}>
                    <span>Отчество</span>
                    <input onChange={handleFormInput} value={patronymic} name={'patronymic'} className={style.input} type="text"/>
                </label>
                <label className={style.label}>
                    <span>Электронная почта</span>
                    <input onChange={handleFormInput} value={email} name={'email'} required className={style.input} type="email"/>
                </label>
                <label className={style.checkBox}>
                    <input required type="checkbox"/>
                    <Link className={style.link} to={'/privacy'}>Согласие на обработку персональных данных</Link>
                </label>
                <motion.button
                    className={style.btnSubscription}
                    whileHover={{scale: 1.1}}
                    whileTap={{scale: 0.9}}
                >Подписаться</motion.button>
            </form>
        );
    }

};

export default Form;
