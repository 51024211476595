import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";



export const fetchVeterans = createAsyncThunk(
    'veterans/fetchVeterans',
    async ({pagination}, {rejectedWithValue})=>{
        try {
            const response = await fetch(process.env.REACT_APP_URL+`/api/nashi-veteranies?populate[0]=image,podvig,subscriptionImg&populate[1]=medals.image&populate[2]=rod_vojsk.emblema&pagination[start]=${pagination}&pagination[limit]=1`)
            if (!response.ok){
                return rejectedWithValue('Страница не найдена')
            }
            return await response.json()
        }
        catch (error){
            return rejectedWithValue(error.message)
        }
    }
)
const veteransSlice = createSlice({
    name: 'veterans',
    initialState: {
        status: 'load',
        error: null,
        serverEmpty: false,
        pagination: 0,
        content: [],
    },
    reducers: {
        incPaginationVeterans(state){
            state.pagination = state.pagination+1
        }
    },
    extraReducers:{
        [fetchVeterans.pending]:(state)=>{
            if(!state.content.length){
                state.status='loading'
            }
            state.error = null
        },
        [fetchVeterans.fulfilled]:(state, action)=>{
            const {data, meta} = action.payload
            const totalVeteransServer = meta.pagination.total
            state.status='loaded'
            state.serverEmpty = false;
            if (data.length){
                const veteransArr2 = []
                data.forEach(elem=>{
                    const index = state.content.findIndex(i=>
                        elem.id===i.id
                    )
                    if(index===-1){
                        veteransArr2.push(elem)
                    }
                })
                state.content = [...state.content, ...veteransArr2]
            }
            if(state.pagination+1===totalVeteransServer){
                state.serverEmpty = true
            }
        },
    }
})
export const {incPaginationVeterans} = veteransSlice.actions
export default veteransSlice.reducer
