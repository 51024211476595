import React from 'react';
import style from './Contacts.module.sass'
const Contacts = () => {
    return (
        <section className={style.contacts}>
            <h1 className={style.title}>Наши координаты</h1>
            <h3 className={style.subtitle}>Региональная общественная организация – Общество «Красноярское землячество»</h3>
            <div className={style.address}>
                <span>Адрес:</span>
                <p>107031, г.Москва, Нижний Кисельный переулок, д.10</p>
            </div>
            <div className={style.contactElem}>
                <div className={style.elem}>
                    <span>Email</span>
                    <a href="mailto:kraszeml24@mail.ru">kraszeml24@mail.ru</a>
                </div>
                <div className={style.elem}>
                    <span>Телефон</span>
                    <a href="tel:+74951093304">8 (495) 109-33-04 </a>
                </div>
            </div>
            <iframe
                title={'yandexMap'}
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A5ba0cea5724ef263b2d5df09686836df7bd3d8b3dc2488eb94532d1da4042e6c&amp;source=constructor"
                width="100%" height="400" frameBorder="0"/>
        </section>
    );
};

export default Contacts;
