// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Privacy_container__V7xwP {\n  max-width: 1200px;\n  margin: 0 auto;\n}\n.Privacy_container__V7xwP h1 {\n  font-size: 28px;\n  text-align: center;\n  margin: 20px 0;\n}\n.Privacy_container__V7xwP h2 {\n  margin: 20px 0;\n}", "",{"version":3,"sources":["webpack://./src/pages/Privacy/Privacy.module.sass"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;AACF;AAAE;EACE,eAAA;EACA,kBAAA;EACA,cAAA;AAEJ;AADE;EACE,cAAA;AAGJ","sourcesContent":[".container\n  max-width: 1200px\n  margin: 0 auto\n  h1\n    font-size: 28px\n    text-align: center\n    margin: 20px 0\n  h2\n    margin: 20px 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Privacy_container__V7xwP"
};
export default ___CSS_LOADER_EXPORT___;
