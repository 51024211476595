import React from 'react';
import style from './DearOurVeterans.module.sass'
import VeteransList from "../../components/VeteransList/VeteransList";

const DearOurVeterans = () => {
    return (
        <section className={style.dearOurVeterans}>
            <h1 className={style.title}>Наши ветераны</h1>
            <VeteransList/>
        </section>
    );
};

export default DearOurVeterans;
