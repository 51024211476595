// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsList_newsListHome__Vs976 {\n  flex-wrap: wrap;\n  display: flex;\n  justify-content: space-evenly;\n  max-width: 1200px;\n}\n\n.NewsList_newsList__QpMDl {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-template-rows: 1fr 1fr;\n  gap: 1.5625vw;\n  max-width: 1200px;\n}\n@media (max-width: 1023px) {\n  .NewsList_newsList__QpMDl {\n    grid-template-columns: 1fr;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/NewsList/NewsList.module.sass"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,aAAA;EACA,6BAAA;EACA,iBAAA;AAHF;;AAIA;EACE,aAAA;EACA,8BAAA;EACA,2BAAA;EACA,aAAA;EACA,iBAAA;AADF;AAEE;EANF;IAOI,0BAAA;EACF;AACF","sourcesContent":["@use 'sass:math'\n@function calcWidth($n)\n  @return $n*math.div(100vw,1920)\n\n.newsListHome\n  flex-wrap: wrap\n  display: flex\n  justify-content: space-evenly\n  max-width: 1200px\n.newsList\n  display: grid\n  grid-template-columns: 1fr 1fr\n  grid-template-rows: 1fr 1fr\n  gap: calcWidth(30)\n  max-width: 1200px\n  @media (max-width: 1023px)\n    grid-template-columns: 1fr\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newsListHome": "NewsList_newsListHome__Vs976",
	"newsList": "NewsList_newsList__QpMDl"
};
export default ___CSS_LOADER_EXPORT___;
