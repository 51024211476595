import React from 'react';
import snegeri1 from '../../assets/image/honoryOfMoscow/snegeri_001.webp'
import snegeri2 from '../../assets/image/honoryOfMoscow/snegeri_002.webp'
import snegeri3 from '../../assets/image/honoryOfMoscow/snegeri_003.webp'
import style from './DefendersOfMoscow.module.sass'
import ScrollBtn from "../../components/ScrollBtn/ScrollBtn";

const DefendersOfMoscow = () => {
    return (
        <section className={style.memorial}>
            <h1 className={style.title}>Сибирякам – защитникам Москвы</h1>
            <p>Придет весна, зазеленеют травы,<br/> И встретит теплые лихие ветерки Волоколамское шоссе — рубеж кровавый, <br/>Где встали насмерть за Москву сибиряки!</p>
            <br/>
            <p>Анатолий Анофриев — сын солдата, погибшего в сражении под Москвой</p>
            <img src={snegeri1} alt="мемориал"/>
            <p>В Подмосковье на 42-м километре Волоколамского шоссе в честь 60-летия разгрома немецко-фашистских войск под Москвой благодарными потомками по инициативе Красноярского землячества воздвигнут памятный мемориал сибирякам, павшим в этой великой битве.</p>
            <br/>
            <p>На фронты Великой Отечественной войны было направлено более 140 стрелковых соединений (дивизий и бригад), из них почти каждая пятая участвовала в московской битве. Кроме того, на фронт ушли десятки отдельных частей и подразделений.</p>
            <br/>
            <p>Невиданное по масштабам сражение развернулось на территории Смоленской, Брянской, Калининской (ныне Тверской), Калужской, Тульской и Московской областей. С обеих сторон в нём участвовало более 7 млн. человек, было сосредоточено 53 тыс. орудий и миномётов, почти 6,5 тыс. танков и более 3 тыс. самолетов.
            </p>
            <br/>
            <p>Наши земляки внесли свой достойный вклад в разгром немецко-фашистских войск под Москвой. Соединения, сформированные в Сибири, сражались на всех главных направлениях и проявили небывалые стойкость, мужество, героизм. 10 сибирских дивизий и одна стрелковая бригада были преобразованы в Гвардейские. В числе первых — 119-я стрелковая дивизия красноярцев.</p>
            <br/>
            <p>Сибирские Землячества в Москве в 1997 году стали инициаторами со-оружения памятника сибирякам-защитникам Москвы и создали Некоммерческий благотворительный фонд «Мемориал — сибирякам» во главе с генералом С.Я. Тимохиным.</p>
            <br/>
            <p>В 1999 году красноярцами — архитектором А.С. Демирхановым и скульптором К.М. Зиничем, при поддержке благотворительного фонда КРАЗа «Вера, Надежда» — были разработаны проект и скульптурная композиция Мемориала. Мэр г. Красноярска П.И. Пимашков помог скульпторам с помещением для работы.</p>
            <br/>
            <p>Организаторскую работу по воплощению проекта в жизнь возглавил председатель правления Землячества В.И. Долгих, избранный председателем Попечительского совета Некоммерческого благотворительного фонда «Мемориал — сибирякам». Норильчане для отливки скульптуры безвозмездно поставили 15 тонн меди.</p>
            <br/>
            <p>Красноярское землячество объединило работу Мемориального фонда с другими сибирскими землячествами: Томским, Алтайским, Иркутским, Западно-Сибирским, Новосибирским, Омским, Кемеровским. При активной поддержке администраций и предприятий своих регионов они собрали для создания Мемориала более 10 млн. рублей. Пять процентов средств было выделено губернатором Красноярского края А.И. Лебедем. А вместе с другими предприятиями края Красноярское землячество внесло в строительство Мемориала более 3 млн. рублей.</p>
            <br/>
            <p>Большой вклад сделали Красноярский комбайновый и Ачинский нефтеперерабатывающий заводы, Торговый дом ОАО «Минал», завод «Красцветмет». Красаэро безвозмездно доставило самолётами из Красноярска в Москву скульптурную композицию.</p>
            <br/>
            <p>Активное участие в сооружении Мемориала приняли М.В. Брюханов, Ю.Г. Лосев, В.Ф. Николайчук, В.В. Плисов, А.Е. Сафонов, В.И. Сергиенко, А.С. Сецкий, П.С. Федирко, А.Г. Хлопонин, Д.Т. Хагажеев, В.М. Шахов, К.М. Чернов. Многие члены Землячева внесли личные средства.</p>
            <br/>
            <p>В реализации этой благородной цели решающими были поддержка и оказание практической помощи мэром Москвы Ю.М. Лужковым и губернатором Московской области Б.В. Громовым.</p>
            <br/>
            <p>Закладка Мемориала состоялась 21 июня 2001 г. В этом знаменательном событии приняли участие первый заместитель мэра Москвы В.П. Шанцев, многие земляки погибших в сражении за Москву сибиряков, местные ветераны.
            </p>
            <img src={snegeri2} alt="закладка мемориала"/>
            <p>
                Основным исполнителем строительных работ была фирма «Монолит» из Томской области во главе с П.А. Сириченко. Координацию работ по сооружению Мемориала взяло на себя Министерство строительства Московской области и его руководитель А.В. Горностаев. Уже к декабрю 2001 года строительство Мемориала было завершено, а 5 декабря, в день 60-летия начала контрнаступления Красной Армии под Москвой он был торжественно открыт.
            </p>
            <br/>
            <p>Мы выполнили священный долг перед павшими в этой битве нашими земляками-сибиряками. На памятных плитах Мемориала увековечены наименования двух армий, 26 дивизий, 6 стрелковых бригад, а также соединений, которым было присвоено звание Гвардейских, имена 19 воинов-сибиряков, удостоенных звания Героя Советского Союза, и трёх Героев Российской Федерации, которым эти звания были присвоены посмертно.</p>
            <br/>
            <p>Издана Книга Памяти о сражавшихся под Москвой, в которую занесены также наименования промышленных предприятий, строительных, транспортных и других организаций, имена отдельных граждан, оказавших содействие в создании Мемориала. Книга Памяти передана на вечное хранение в Музей обороны Москвы.</p>
            <br/>
            <p>
                В канун открытия Мемориала вместе с другими сибирскими землячествами была проведена научно-историческая конференция на тему «Защищая Москву, сибиряки вошли в бессмертие», в работе которой приняли участие более 600 человек, и среди них большинство — ветераны войны и труда, в том числе 300 гостей из Сибири. В докладе председателя Правления Красноярского землячества В.И. Долгих — участника Великой Отечественной войны и обороны Москвы — были представлены уникальные материалы о вкладе Сибири в достижение Победы под Москвой и в Великой Отечественной войне в целом.
            </p>
            <img src={snegeri3} alt="Открытие мемориала"/>
                <ScrollBtn/>
        </section>
    );
};

export default DefendersOfMoscow;
