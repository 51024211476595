import React from 'react';
import style from "./MedalsList.module.sass";
import MedalsItem from "../MedalsItem/MedalsItem";
import PropTypes from "prop-types";


const MedalsList = ({title, medals}) => {
    return (
        <div>
            <h3 className={style.subtitle}>{title}</h3>
            <ul className={style.slugList}>
                {medals.map(item=>
                    <MedalsItem key={item.id} medals={item}/>
                )}
            </ul>
        </div>
    );
};

MedalsList.propTypes={
    title: PropTypes.string,
    medals: PropTypes.array
}
export default MedalsList;
