import React from 'react';

const Donate = () => {
    return (
        <div>
            <h1 style={{textAlign: 'center'}}>В разработке</h1>
        </div>
    );
};

export default Donate;
