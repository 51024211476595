import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const fetchNewsMore = createAsyncThunk(
    'newsMore/fetchNewsMore',
    async ({id}, {rejectedWithValue})=>{
        try{
            const response = await fetch(process.env.REACT_APP_URL+`/api/news/${id}?populate=images`)
            if(!response.ok){
                return rejectedWithValue('Страница не найдена')
            }
            return await response.json()
        }
        catch (error){
            return  rejectedWithValue(error.message)
        }
    }
)
const newsMoreSlice = createSlice({
    name: 'newsMore',
    initialState:{
        status: 'load',
        error: null,
        news: {}
    },
    extraReducers: {
        [fetchNewsMore.pending]:(state)=>{
            state.state='loading'
        },
        [fetchNewsMore.fulfilled]:(state, action)=>{
            state.status = 'loaded'
            state.news = action.payload
        },
        [fetchNewsMore.rejected]:(state, action)=>{
            state.status = 'rejected'
            state.error = action.payload
        }
    }
})

export default newsMoreSlice.reducer
