import React from 'react';
import arrow from '../../assets/image/up-arrow.webp'
import style from './ScrollBtn.module.sass'
import {motion} from "framer-motion";


const ScrollBtn = () => {
    const scroll = ()=>{
        setTimeout(()=>window.scrollTo({
            left:0,
            top: 0,
            behavior: "smooth"
        }), 100)
    }
    return (
        <motion.button
            initial={{opacity: 0.5}}
            animate={{
                scale: 1.2,
            }}
            transition={{
                repeat: Infinity,
                duration: 0.5,
                repeatType: "reverse"
            }}
            whileHover={{
                opacity: 1,
                scale:1.2
            }}
            whileTap={{
                scale: 0.8
            }}
            className={style.scrollBtn}
            onClick={scroll}
        >
            <img className={style.arrowUp} src={arrow} alt="arrow up"/>
        </motion.button>
    );
};

export default ScrollBtn;
