import React from 'react';
import SubMenuItem from "../SubMenuItem/SubMenuItem";
import PropTypes from "prop-types";
import MenuItem from "../MenuItem/MenuItem";
const MenuList = ({listMenu}) => {
    return (
        <ul className={'navList'}>
            {listMenu.map((elem, index) => {
                const {subMenu} = elem;
                return subMenu?<SubMenuItem key={index} subMenuList={elem}/>:<MenuItem key={index} dropDown={false} info={elem}/>
                }
            )}
        </ul>
    );
};

MenuList.propsType = {
    listMenu: PropTypes.array
}
export default MenuList;
