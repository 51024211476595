import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changeActiveMenu} from "../../Redux/Reducer/menuSlice";
import MenuItem from "../MenuItem/MenuItem";
import PropTypes from "prop-types";

const SubMenuItem = ({subMenuList}) => {
    const{activeMenu} = useSelector(state=>state.menu)
    const dispatch = useDispatch()
    const {subMenu, title} = subMenuList
    const menuStart = ()=> {
        dispatch(changeActiveMenu('start'))
    }
    const handleChangeActiveMenu = e=>e.target.id? dispatch(changeActiveMenu(e.target.id)):null
    return (
        <li
            onMouseEnter={()=>dispatch(changeActiveMenu(subMenu.id))}
            onMouseLeave={()=>dispatch(changeActiveMenu('start'))}
            onClick={handleChangeActiveMenu}
            className={'navItem'}
        >
            <span id={subMenu.id} className={'navLink navLink_drop'}>{title}</span>
            <ul
                className={activeMenu===subMenu.id?'navList navList_dropdown dropdownList dropdownList_active':'navList navList_dropdown dropdownList'}
            >
                <li className={'dropdownList_item mobileBack'}>
                    <span onClick={()=>menuStart()} className={'dropdownList_link mobileBack_link'}>Назад</span>
                </li>
                {subMenu.submenuList.map((element,index)=>
                    <MenuItem key={index} dropDown={true} info={element}/>
                )}
            </ul>
        </li>
    );
};

SubMenuItem.propsType = {
    subMenuList: PropTypes.array
}
export default SubMenuItem;
