// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".News_newsBox__A\\+LPg {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-width: 1200px;\n  margin: 0 auto;\n}\n\n.News_title__tauzL {\n  margin: 20px 0;\n  font-size: 32px;\n}\n@media (max-width: 1023px) {\n  .News_title__tauzL {\n    margin: 10px 0 0 0;\n    font-size: 24px;\n  }\n}\n\n.News_span__zZV6j {\n  color: black;\n  border: none;\n  background-color: transparent;\n  cursor: pointer;\n  font-family: \"Roboto-Medium\", sans-serif;\n  font-size: 25px;\n  margin: 10px 0 30px 0;\n}\n.News_span__zZV6j:disabled {\n  color: rgba(0, 0, 0, 0.41);\n}", "",{"version":3,"sources":["webpack://./src/pages/News/News.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAAA;EACE,cAAA;EACA,eAAA;AAGF;AAFE;EAHF;IAII,kBAAA;IACA,eAAA;EAKF;AACF;;AALA;EACE,YAAA;EACA,YAAA;EACA,6BAAA;EACA,eAAA;EACA,wCAAA;EACA,eAAA;EACA,qBAAA;AAQF;AAPE;EACE,0BAAA;AASJ","sourcesContent":[".newsBox\n  display: flex\n  flex-direction: column\n  align-items: center\n  max-width: 1200px\n  margin: 0 auto\n.title\n  margin: 20px 0\n  font-size: 32px\n  @media (max-width: 1023px)\n    margin: 10px 0 0 0\n    font-size: 24px\n.span\n  color: black\n  border: none\n  background-color: transparent\n  cursor: pointer\n  font-family: 'Roboto-Medium', sans-serif\n  font-size: 25px\n  margin: 10px 0 30px 0\n  &:disabled\n    color: rgba(0, 0, 0, 0.41)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newsBox": "News_newsBox__A+LPg",
	"title": "News_title__tauzL",
	"span": "News_span__zZV6j"
};
export default ___CSS_LOADER_EXPORT___;
