// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DearOurVeterans_dearOurVeterans__q\\+fib {\n  box-sizing: border-box;\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 20px;\n}\n.DearOurVeterans_dearOurVeterans__q\\+fib img {\n  max-width: 100%;\n  height: auto;\n}\n\n.DearOurVeterans_title__kKS4s {\n  margin: 30px 0;\n  font-size: 30px;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/DearOurVeterans/DearOurVeterans.module.sass"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;AACF;AAAE;EACE,eAAA;EACA,YAAA;AAEJ;;AADA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;AAIF","sourcesContent":[".dearOurVeterans\n  box-sizing: border-box\n  max-width: 1200px\n  margin: 0 auto\n  padding: 20px\n  img\n    max-width: 100%\n    height: auto\n.title\n  margin: 30px 0\n  font-size: 30px\n  text-align: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dearOurVeterans": "DearOurVeterans_dearOurVeterans__q+fib",
	"title": "DearOurVeterans_title__kKS4s"
};
export default ___CSS_LOADER_EXPORT___;
