import React from 'react';
import style from "./HonoraryCitizens.module.sass"
import HonoraryCitizensList from "../../components/HonoraryCitizensList/HonoraryCitizensList";

const HonoraryCitizens = () => {
    return (
        <section className={style.honoraryCitizens}>
            <h1 className={style.title}>Почетные граждане Красноярского края</h1>
            <HonoraryCitizensList/>
        </section>
    );
};

export default HonoraryCitizens;
