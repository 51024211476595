// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MedalsItem_slugItem__wHM-f {\n  box-sizing: border-box;\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.MedalsItem_slugItem__wHM-f img {\n  max-width: 200px;\n  height: auto;\n}\n.MedalsItem_slugItem__wHM-f p {\n  font-size: 16px;\n  font-family: \"Roboto-Medium\", sans-serif;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/MedalsItem/MedalsItem.module.sass"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAAE;EACE,gBAAA;EACA,YAAA;AAEJ;AADE;EACE,eAAA;EACA,wCAAA;EACA,kBAAA;AAGJ","sourcesContent":[".slugItem\n  box-sizing: border-box\n  padding: 10px\n  display: flex\n  flex-direction: column\n  align-items: center\n  img\n    max-width: 200px\n    height: auto\n  p\n    font-size: 16px\n    font-family: 'Roboto-Medium', sans-serif\n    text-align: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slugItem": "MedalsItem_slugItem__wHM-f"
};
export default ___CSS_LOADER_EXPORT___;
