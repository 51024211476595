// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Subscription_subscription__vqhKk {\n  padding: 0 15px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-width: 1200px;\n  margin: 0 auto;\n}\n\n.Subscription_title__xYwY8 {\n  font-size: 30px;\n  margin: 30px 0;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/Subscription/Subscription.module.sass"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAAA;EACE,eAAA;EACA,cAAA;EACA,kBAAA;AAGF","sourcesContent":[".subscription\n  padding: 0 15px\n  display: flex\n  flex-direction: column\n  align-items: center\n  max-width: 1200px\n  margin: 0 auto\n.title\n  font-size: 30px\n  margin: 30px 0\n  text-align: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscription": "Subscription_subscription__vqhKk",
	"title": "Subscription_title__xYwY8"
};
export default ___CSS_LOADER_EXPORT___;
