import React, {useEffect}  from 'react';
import {useDispatch, useSelector} from "react-redux";
import Preloader from "../Preloader/Preloader";
import Error from "../Error/Error";
import style from "./HonoraryCitizensList.module.sass";
import ScrollBtn from "../ScrollBtn/ScrollBtn";
import {fetchHonoraryCitizens, incPagination} from "../../Redux/Reducer/honoraryCitizensSlice";
import HonoraryCitizensItem from "../HonoraryCitizensItem/HonoraryCitizensItem";

const HonoraryCitizensList = () => {
    const {content, error,status,pagination, serverEmpty} = useSelector(state=>state.honoraryCitizen)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(fetchHonoraryCitizens({pagination}))
    }, [dispatch,pagination])

    if(status==='loading'){
        return <Preloader/>
    }
    if(status==='rejected'){
        return <Error message={error}/>
    }
    if(status==='loaded'){
        return (
            <ul className={style.honoraryCitizensList}>
                {content.map(item=><HonoraryCitizensItem key={item.id} contentItem={item}/>)}
                <button
                    disabled={serverEmpty}
                    className={style.btnHonorary}
                    onClick={()=>dispatch(incPagination())}
                >Ещё..</button>
                <ScrollBtn/>
            </ul>
        );
    }

};

export default HonoraryCitizensList;
