// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Presidium_presidium__gSz8I {\n  z-index: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.Presidium_title__5nZmL {\n  text-align: center;\n  margin: 3.2407407407vh 0;\n  font-size: 1.6666666667vw;\n}\n@media (min-width: 321px) and (max-width: 768px) {\n  .Presidium_title__5nZmL {\n    font-size: 20px;\n    margin: 25px 0;\n  }\n}\n@media (max-width: 320px) {\n  .Presidium_title__5nZmL {\n    font-size: 18px;\n    margin: 20px 0;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/Presidium/Presidium.module.sass"],"names":[],"mappings":"AAMA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AALF;;AAMA;EACE,kBAAA;EACA,wBAAA;EACA,yBAAA;AAHF;AAIE;EAJF;IAKI,eAAA;IACA,cAAA;EADF;AACF;AACE;EAPF;IAQI,eAAA;IACA,cAAA;EAEF;AACF","sourcesContent":["@use 'sass:math'\n@function calcWidth($n)\n  @return $n*math.div(100vw,1440)\n@function calcHeight($n)\n  @return $n*math.div(100vh,1080)\n\n.presidium\n  z-index: 0\n  display: flex\n  flex-direction: column\n  align-items: center\n.title\n  text-align: center\n  margin: calcHeight(35) 0\n  font-size: calcWidth(24)\n  @media (min-width: 321px) and (max-width: 768px)\n    font-size: 20px\n    margin: 25px 0\n  @media (max-width: 320px)\n    font-size: 18px\n    margin: 20px 0\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"presidium": "Presidium_presidium__gSz8I",
	"title": "Presidium_title__5nZmL"
};
export default ___CSS_LOADER_EXPORT___;
