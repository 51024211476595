import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PresidiumItem from "../PresidiumItem/PresidiumItem";
import {fetchPresidium, incPagination} from "../../Redux/Reducer/presidiumSlice";
import style from './PresidiumList.module.sass'
import Preloader from "../Preloader/Preloader";
import Error from "../Error/Error";
import ScrollBtn from "../ScrollBtn/ScrollBtn";

const PresidiumList = () => {
    const {content, error, status,pagination, serverEmpty} = useSelector(state=>state.presidium)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(fetchPresidium({pagination}))
    }, [dispatch,pagination])

    if(status==='loading'){
        return <Preloader/>
    }
    if(status==='rejected'){
        return <Error message={error}/>
    }
    if(status==='loaded'){
        return (
            <ul className={style.presidiumList}>
                {content.map(item=><PresidiumItem key={item.id} contentItem={item}/>)}
                <button
                    disabled={serverEmpty}
                    className={style.btnPresidium}
                    onClick={()=>dispatch(incPagination())}
                >Ещё..</button>
                <ScrollBtn/>
            </ul>
        );
    }


};

export default PresidiumList;
