import React from 'react';
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {changeActiveMenu, changeMobileMenu} from "../../Redux/Reducer/menuSlice";
import {motion} from "framer-motion";
import PropTypes from "prop-types";

const MenuItem = ({dropDown,info}) => {
    const hover = {
        color: '#66b5f1',
        scale: 1.1
    }
    const dispatch = useDispatch()
    const menuStart = ()=> {
        dispatch(changeActiveMenu('start'))
    }
    return (
        <motion.li
            whileHover={hover}
            whileTap={{scale: 0.95}}
            onClick={()=>{
                menuStart();
                dispatch(changeMobileMenu(false));
            }}
            className={dropDown?'dropdownList_item':'navItem'}>
            <Link to={info.link} className={dropDown?'dropdownList_link':'navLink'}>{info.title}</Link>
        </motion.li>
    );
};

MenuItem.propsType = {
    dropDown: PropTypes.string,
    info: PropTypes.string
}
export default MenuItem;
