import React, {useLayoutEffect} from 'react';
import ScrollBtn from "../../components/ScrollBtn/ScrollBtn";
import style from './AboutKrasnoyarsk.module.sass'

const AboutKrasnoyarsk = () => {

        const scroll = ()=>{
               window.scrollTo({
                        left:0,
                        top: 0,
                })
        }
        useLayoutEffect(()=>{
                scroll()
        }, [])
    return (
        <section className={style.container}>
                <div style={{padding: '30px'}}>
                        <h1 className={style.title}>О Красноярском крае</h1>
                        <h2>Красноярский край</h2>
                        <br/>
                        <p>Красноярский край - второй по площади субъект Российской Федерации, занимает 2366,8 тыс. кв. км (или 13, 86% территории страны). Красноярский край входит в Сибирский федеральный округ. На востоке край граничит с Республикой Саха (Якутия) и Иркутской областью, на юге – с Республикой Тыва и с Республикой Хакасией, на западе – с Кемеровской и Томской областями, а также с Ханты-Мансийским и Ямало-Ненецким автономными округами.</p>
                        <br/>
                        <p>Красноярский край был образован 7 декабря 1934 года постановлением Президиума Всероссийского центрального исполнительного комитета (ВЦИК) РСФСР. В состав региона вошли 31 район, Хакасская автономная область, Таймырский и Эвенкийский национальные округа. Центром стал город Красноярск. Край был образован почти в границах бывшей Енисейской губернии, площадь его составляла свыше двух миллионов квадратных километров.</p>
                        <br/>
                        <p>В 1991 году Хакасская автономная область вышла из состава края и образовала самостоятельный субъект Российской Федерации - Республику Хакасию. Самостоятельными субъектами Российской Федерации стали два автономных округа: Таймырский (Долгано-Ненецкий) и Эвенкийский, хотя они территориально и входили в состав края.
                        </p>
                        <br/>
                        <p>В 2007 году в результате референдума в состав края вошли два автономных округа - Эвенкийский и Таймырский (Долгано-Ненецкий), которые были преобразованы в муниципальные районы с особым статусом. День проведения референдума - 17 апреля - в крае объявлен праздничным днём - Днём единения.
                        </p>
                        <br/>
                        <h2>ГЕОГРАФИЧЕСКОЕ ПОЛОЖЕНИЕ</h2>
                        <br/>
                        <p>Красноярский край расположен в основном в пределах Восточной Сибири, в бассейне реки Енисей. Вдоль левого берега Енисея располагается низменная долина, а вдоль правого - Среднесибирское плоскогорье, высота которого достигает 500-700 м выше уровня моря. На севере край омывается Карским морем и морем Лаптевых.
                        </p>
                        <br/>
                        <p>Протяжённость территории от севера до горных районов Южной Сибири почти 3000 км. На территории края в окрестностях озера Виви в Эвенкии расположен географический центр России. Кроме того, на территории края находится мыс Челюскин – крайняя северная точка материковой части России и всей Азии. К Красноярскому краю относятся архипелаг Северная Земля, острова Норденшельда, Вилькицкого, Сибирякова, Диксон и др.
                        </p>
                        <br/>
                        <p>Рельеф Красноярского края разнообразен. На большом протяжении северной части река Енисей проложила долину на стыке двух тектонических структур. С правобережья к долине реки уступами спускается сложенное древними породами Среднесибирское плоскогорье и Енисейский кряж. На левом берегу реки расположена Западно-Сибирская низменность, которая на севере смыкается с обширной Енисейско-Хатангской низменностью, занимающей часть Таймырского полуострова. Юг края занимают горы и межгорные впадины Алтае-Саянской горной страны. Среднесибирское плоскогорье сложено песчаниками, известняками, известняками, сланцами, углем, которые во многих местах перекрыты магматическими излияниями - траппами. В северо-западной части плоскогорья поднимается плато Путорана, высоты которого местами превышают 1600 м, а высшая точка – гора Камень – 1701 м.
                        </p>
                        <br/>
                        <p>На левобережье Енисея расположена восточная часть Западно-Сибирской низменности. Она неоднократно перекрывалась ледниками, поэтому рельеф в основном равнинно-холмистый, имеется много озер, болот и рек.
                        </p>
                        <br/>
                        <p>Средне-Сибирская низменность занимает большую часть Таймырского полуострова. Далеко простираются холмы и гряды высотой до 12 м. На севере полуострова от Енисейского залива до моря Лаптевых протянулись невысокие горы Бырранга, средняя высота которых составляет 400-600 м.
                        </p>
                        <br/>
                        <p>В южной части края высятся хребты Восточного и Западного Саян и Кузнецкого Алатау. У подножия хребтов лежит знаменитая Минусинская котловина, где благоприятные климатические условия. Восточный Саян начинается немного западнее города Красноярска и проходит на юго-востоке до гор Забайкалья. Эта обширная горная область состоит из многих горных хребтов, впадин и высоких плато. Здесь есть несколько плосковершинных хребтов высотой до 900 м, которые называют "белогорьями" - Манское, Канское, Пезинское и другие. Название "белогорье" пошло от русских первопроходцев, именовавших так в XVII в. "горы, в летнее время снегом покрытые". В горах Восточного Саяна имеется много карстово-спелеологических участков. Сегодня на территории края зафиксировано самое большое в стране количество пещер - около 150, среди них – длиннейшая пещера России Большая Орешная, протяженностью свыше 50 км.
                        </p>
                        <br/>
                        <p>Западный Саян протянулся на южной границе Красноярского края более чем на 650 км. Он состоит из многих хребтов – Ергаки, Саянский, Куртушибинский, Тазарама, Джебашский, Араданский и др.) и древних поверхностей выравнивания. На юго-западе протянулся Кузнецкий Алатау, отделяющий Минусинскую впадину от Кузнецкой.
                        </p>
                        <br/>
                        <h2>КЛИМАТ</h2>
                        <br/>
                        <p>На территории края выделяют арктический, субарктический и умеренный климатические пояса. В связи с большой протяженностью региона климат края очень неоднороден. Средняя температура января составляет от -360С на севере до -180С на юге, средняя температура июля составляет от +130С на севере до +20+250С на юге.</p>
                        <br/>
                        <p>В крае выделяют северную, центральную, южную, западную и восточную климатические области. Климат северной части особенно суров. Длительная суровая зима сопровождается сильными ветрами и высокой влажностью. Прохладное северное лето очень коротко. На Таймырском полуострове безморозный период практически отсутствует – почти каждый день температура воздуха может опускаться до ноля и ниже градусов. В равнинной центральной области климат характеризуется относительно жарким и коротким летом, продолжительной холодной зимой, значительными колебаниями температур. Южная часть края отличается теплым летом и умеренной зимой. Сухой и чистый воздух, обилие солнечных дней, целебные воды создают благоприятные климатические условия для лечения и отдыха. В западной части края выпадает большее количество осадков.</p>
                        <br/>
                        <p>Самую северную, островную, часть края занимает зона льдов и арктических  пустынь. По рельефу это холмисто-грядовая равнина. Зона тундры и лесотундры имеет ширину 1000-1200 км и включает полуостров Таймыр и горную область Бырранга. Типичная тундра имеет холмистый рельеф с большим количеством озер. Зона тайги занимает значительную часть территории края. Типичная степь расположена на юге края и занимает большую часть Минусинской впадины, Чулымско-Енисейской котловины.
                        </p>
                        <br/>
                        <p>Земледелие в крае возможно примерно до широты Енисейска, а севернее – только очагами.</p>
                        <br/>
                        <h2>НАСЕЛЕНИЕ</h2>
                        <br/>
                        <p>Численность населения Красноярского края,  по данным Красноярскстата на 1 января 2021 года, составляет 2 855 899 человек. Плотность населения составляет 1,21 чел./ кв. км. Городское население составляет 77 %. Около 80 % населения края живут к югу от Ангары — на одной десятой территории края. В Красноярском крае образовано 544 муниципальных образования, включая 17 городских округов, 3 муниципальных округа и 41 муниципальный район, 26 городских и 457  сельских поселений. Всего на территории края расположено более 1700 населенных пунктов.
                        </p>
                        <br/>
                        <p>Основные города: Красноярск, Норильск, Ачинск, Енисейск, Дивногорск, Канск, Лесосибирск, Минусинск, Дудинка, Сосновоборск, ЗАТО г. Зеленогорск, ЗАТО г. Железногорск, Заозерный, Боготол, Бородино, Уяр, Иланский, Кодинск, Ужур, Назарово, Шарыпово, Игарка, Артёмовск, ЗАТО п. Солнечный, ЗАТО п. Кедровый.
                        </p>
                        <br/>
                        <p>На территории края проживают представители 159 национальностей. Национальный состав населения от общей численности населения, по данным Всероссийской переписи 2010 г., составляет: русские (88,08%), украинцы (1,34%), татары (1,23%), немцы (0,79%), азербайджанцы (0,58%), белорусы (0,35%), чуваши (0,38 %), армяне (0,38 %), киргизы (0,30%), узбеки (0,23%), таджики (0,23%), мордва (0,15%), марийцы (0,12%), башкиры (0,11%), тувинцы (0,10%), лезгины (0,10%), молдаване (0,10 %), хакасы (0,15%), эстонцы, эстонцы-сету (0,08%), латыши (2,184 – 0,07%).</p>
                        <br/>
                        <p>Численность коренных малочисленных народов Севера, по переписи 2010 г., составляет: долганы (0,21 %), эвенки (0,16%), ненцы (0,13%), якуты (0,05%), кеты (0,03%), нганасаны (0,02%), селькупы (0,01%), энцы (0,01%), чулымцы (0,01%).
                        </p>
                        <br/>
                        <h2>ПОЛЕЗНЫЕ ИСКОПАЕМЫЕ</h2>
                        <br/>
                        <p>Красноярский край занимает одно из ведущих мест в России по запасам минеральных ресурсов и полезных ископаемых. В его недрах встречаются нефть, газ, железные руды, уголь, цветные и редкие металлы, нерудные минералы. Всего в крае насчитывается более 1200 месторождений полезных ископаемых, в том числе 106 месторождений бурого и каменного угля, 193 месторождения торфа, 66 – черных и цветных металлов, 15 – редких и рассеянных элементов, 301 – благородных металлов, 94 месторождения неметаллических полезных ископаемых (абразивов, глин, известняков флюсовых, магнезита, нефелиновых руд, природных облицовочных камней, пьезооптического сырья, формовочного сырья, цветных камней), более 360 месторождений общераспространенных полезных ископаемых (строительного камня, песчано-гравийных смесей, керамзитовых смесей, песка), 119 месторождений пресных подземных вод, 12 минеральных месторождений подземных вод, 33 месторождения углеводородного сырья.
                        </p>
                        <br/>
                        <p>В крае сосредоточены основные запасы платины и платиноидов, медно-никелевых руд, основные месторождения которых находятся на севере края, в том числе на Таймырском полуострове. Мировой известностью пользуется Норильский горнорудный район (месторождения Норильск-1, Октябрьское и Талнахское), где добывают медь, никель, кобальт, платину.
                        </p>
                        <br/>
                        <p>На территории края имеется 33 месторождения углеводородного сырья. Крупнейшие нефтегазовые месторождения края находятся в Туруханском и Таймырском (Долгано-Ненецком) районах – это месторождения Ванкорской группы (Ванкорское, Сузунское, Тагульское и др.) и на юге Эвенкийского района – месторождения Юрубчено-Тохомской зоны (Юрубченское, Куюмбинское, Собинское, Пайгинское,  Имбинское, Берямбинское и др.).
                        </p>
                        <br/>
                        <p>Краю принадлежит ведущее место в России по общим геологическим запасам угля – около 70%, которые сосредоточены в Канско-Ачинском, Тунгусском, Таймырском и Минусинском угольных бассейнах. Наиболее активно осваиваются запасы уникального по  экономико-географическому положению и запасам Канско-Ачинского буроугольного бассейна, расположенного вдоль Транссибирской железной дороги.
                        </p>
                        <br/>
                        <p>По общему потенциалу золотоносности и золотодобычи край является традиционно одним из лидеров в Российской Федерации - на территории края разведано около 300 коренных и россыпных месторождений. Основные разрабатываемые запасы золота сосредоточены на территории Северо-Енисейского, Мотыгинского районов (Олимпиадинское, Благодатное, Эльдорадо, Васильевское и др.).
                        </p>
                        <br/>
                        <p>Ангаро-Енисейская провинция (Енисейский кряж и прилегающая к нему Сибирская платформа) и зона Нижнего Приангарья богаты бокситами и нефелиновыми рудами для производства алюминия, а также железными рудами, которые находятся в государственном резерве.</p>
                        <br/>
                        <p>Территория Нижнего Приангарья занимает ведущее положение в России по запасам магнезитов, сосредоточенным в крупных месторождениях. На территории края разрабатывается Горевское месторождение полиметаллов – уникальное не только по запасам, но и по содержанию свинца и цинка (до 6% и выше свинца в руде). Из свинцово-цинковых руд попутно добывают серебро, кадмий и др. металлы.
                        </p>
                        <br/>
                        <p>Из неметаллических полезных ископаемых в крае разрабатываются месторождения флюсовых известняков, поваренной соли, талька, графита, тугоплавких и огнеупорных глин, апатита, вермикулита и формовочных материалов, а также строительных материалов.
                        </p>
                        <br/>
                        <p>На севере края в пределах Попигайской кольцевой структуры обнаружены уникальные месторождения импактных технических алмазов (Ударное, Скальное). По общим запасам алмазов эта группа месторождений превышает все известные в мире алмазоносные провинции.
                        </p>
                        <br/>
                        <p>Кроме того, в крае разведаны месторождения жадеита (Борусское) и нефрита (Кантегирское и Куртушибинское), хризолита, кварца и кварцитов. На Енисейском кряже найден розовый турмалин (рубеллит) и розовый тальк. На севере Красноярского края имеется янтарь и датолит (Норильский промышленный район). В Минусинской котловине - родусит-асбест. В центральных районах края - аметист (Нижне-Канское, Краснокаменское), змеевик (Верхнесоболевское, Березовское) и мраморный оникс (Торгашинское).
                        </p>
                        <br/>
                        <p>На территории Красноярского края также эксплуатируется три месторождения минеральных вод: Кожановское (Балахтинский район), Нанжульское (окрестности Красноярска) и Тагарское (Минусинский район).
                        </p>
                        <br/>
                        <a style={{fontStyle: 'italic', textDecoration: 'underline', fontFamily: 'Roboto-Medium'}} href="http://www.krskstate.ru/about/kray">Полная версии этой страницы тут</a>
                </div>
                <ScrollBtn/>
        </section>
    );
};

export default AboutKrasnoyarsk;
