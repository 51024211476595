import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
export const fetchSubscription = createAsyncThunk(
    'subscription/fetchSubscription',
    async ({body}, {rejectWithValue})=>{
        try {
            const response = await fetch(process.env.REACT_APP_URL + '/api/subscriptions',{
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: body
            })
            if(!response.ok){
                throw new Error('Не корректные данные или такой адресс уже подписан на наши обновления');
            }
            return await response
        }
        catch (error){
            return rejectWithValue(error.message)
        }
    }
)
const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState:{
        status: '',
        email: '',
        name: '',
        surname: '',
        patronymic: '',
        error: ''
    },
    reducers:{
        changeForm(state, action){
            const {input, value} = action.payload
            state[input] = value
        },
        clearForm(state){
            state.email= ''
            state.name= ''
            state.surname= ''
            state.patronymic= ''
            state.status = 'load'
        }
    },
    extraReducers: {
        [fetchSubscription.pending]:(state)=>{
           state.status = 'loading'
            state.error= null
        },
        [fetchSubscription.fulfilled]:(state,action)=>{
            state.status = 'loaded'
            console.log(action.payload)
        },
        [fetchSubscription.rejected]:(state,action)=>{
            state.status = 'rejected'
            state.error = action.payload
        }
    }
})

export const {changeForm, clearForm} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
