import React from 'react';
import Form from "../../components/Form/Form";
import style from './Subscription.module.sass'
import {useDispatch} from "react-redux";
import {clearForm} from "../../Redux/Reducer/subscriptionSlice";

const Subscription = () => {
    const dispatch = useDispatch()
    dispatch(clearForm())
    return (
        <section className={style.subscription}>
            <h1 className={style.title}>Подпишитесь на наши обновления</h1>
            <Form/>
        </section>
    );
};

export default Subscription;
