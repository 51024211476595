import React from 'react';
import style from './Club69.module.sass'
import people from '../../assets/image/69-parallel.webp'

const Club69 = () => {
    return (
        <section className={style.club69}>
            <h1>КЛУБ «69-я ПАРАЛЛЕЛЬ»</h1>
            <br/>
            <h3>История создания</h3>
            <br/>
            <p>Встречи бывших норильчан в Москве зародились еще в конце сороковых — начале пятидесятых годов среди студентов, поступивших в ВУЗы столицы. Еще тогда была необходимость создания в столице общественного центра, который объединял бы норильчан. Но произошло это только в 1990-е годы — во время крупных перемен в РАО «Норильский никель».</p>
            <br/>
            <p>Ликвидация московского представительства компании подтолкнула активистов к организации какого-то органа. Активистами выступили норильские горняки, прежде всего рудника «Заполярный», — И.С. Аристов, Р.М. Крестников, В.С. Ярич. В этот период были сделаны первые попытки проведения не только общественных мероприятий, но и осуществления некоторых коммерческих проектов с привлечением бывших специалистов норильского комбината в тех направлениях, которые способствовали улучшению материального положения ветеранов Норильска.</p>
            <br/>
            <p>Первая встреча состоялась в 1993 году. Собралось около сорока человек, работавших в разные годы в Норильске: руководители, инженерно-технические работники, рабочие. Никаких задач не ставилось, на встречах вспоминали прошедшие годы, отмечали юбилеи. Норильчане по возможности помогали друг другу, к общей печали хоронили друзей. А когда образовалось Красноярское землячество, другая группа норильчан по инициативе Г.И. Касабовой, в прошлом журналиста газеты «Заполярная правда», и Л.Н. Картиной, бывшего инженера института «Норильскпроект», стали подумывать об объединении земляков с соответствующей юридической регистрацией. И 18 февраля 1999 года Управлением юстиции Москвы была зарегистрирована региональная общественная организация — Общество Красноярское землячество «69-я параллель».</p>
            <br/>
            <p>В Клубе «69-я параллель» сегодня зарегистрировано около 800 участников, вместе с тем только в Москве живут и работают уже несколько тысяч норильчан.</p>
            <br/>
            <h3>«О времени, о Норильске, о себе...»</h3>
            <br/>
            <p>Клуб «69-я параллель» в своем уставе предусматривает достаточно много направлений работы, но все-таки главным считается возможность общения норильчан, в разное время приехавших в Москву.</p>
            <br/>
            <p>На каждой встрече обязательно делаются информационные сообщения о Норильске, о комбинате, а теперь уже о РАО «Норильский никель». На встречи приглашаются руководящие работники этой компании, депутаты Государственной Думы, представители Совета Федерации, мэрии Норильска. Обязательно предусматривается культурная программа, в рамках которой нередко выступают творческие коллективы из Норильска.</p>
            <br/>
            <p>
                Постепенно сфера деятельности Клуба дополнилась новым направлением, связанным с издательской деятельностью. На одной из встреч клуба зародилась идея собрать воспоминания норильчан и написать коллективную историю норильского промышленного района. За это дело взялась Галина Ивановна Касабова.
            </p>
            <br/>
            <p>Сначала появился первый том «О времени, о Норильске, о себе...». Постепенно желающих участвовать в издании стало больше. Из-под редактирующего пера вышло уже девять таких книг с авторством более сотни земляков, и работа эта продолжается.</p>
            <br/>
            <p>Параллельно с этим осуществлялось издание воспоминаний о знаменитых норильчанах, о предприятиях, подразделениях норильского промышленного района. В этой сфере успешно работали Владимир Иванович Полищук, Михаил Яковлевич Важнов, Михаил Колпаков, Евгений Александрович Сорочкин.</p>
            <br/>
            <p>Участникам Клуба удалось подготовить материалы и издать уникальную книгу «Планета культуры — Норильск». Это первая книга о норильской культуре, о людях, много лет работавших в этом направлении.</p>
            <br/>
            <p>Члены Клуба «69-я параллель» приняли участие в реализации красочного альманаха «Преодоление». На суперобложке надпись — «Норильский комсомольский десант (1956 год)». Книга-альбом богато иллюстрирована редкими фотографиями из личных архивов авторов и героев, репродукциями картин сибиряков, стихами, документальными свидетельствами.</p>
            <br/>
            <p>Большую помощь в этой работе Клубу оказывают журналисты газеты «Заполярный вестник» и редакция корпоративного журнала «Норильский никель».</p>
            <img className={style.imgClub} src={people} alt="club people"/>
        </section>
    );
};

export default Club69;
