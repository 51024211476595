import React from 'react';
import style from './NewsItem.module.sass'
import {motion} from "framer-motion";
import {useNavigate} from "react-router";
import PropTypes from "prop-types";

const NewsItem = ({content, id}) => {
    const {images} = content
    const urlImg = images.data[0].attributes.formats.small.url
    const url = process.env.REACT_APP_URL
    const date = content.date.split('-').reverse().join('.')
    const navigate = useNavigate()
    return (
        <motion.li
            onClick={()=>navigate(`/news/${id}`)}
            id={id}
            className={style.item}
            whileHover={{scale: 1.05}}
            whileTap={{scale: 0.98}}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity:1
            }}
            transition={{
                duration: 0.3
            }}
        >
            <img src={url+urlImg} alt="img"/>
            <h4>{content.title}</h4>
            <span>{date}</span>
        </motion.li>
    );
};
NewsItem.propsType = {
    content: PropTypes.object,
    id: PropTypes.number
}
export default NewsItem;
