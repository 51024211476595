import React from 'react';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid'
import style from './PresidiumItem.module.sass'
const PresidiumItem = ({contentItem}) => {
    const {name, rangs, image} = contentItem.attributes

    return (
        <li className={style.item}>
            <img className={style.imgItem} src={process.env.REACT_APP_URL+image.data.attributes.url} alt={name}/>
            <div className={style.boxContent}>
                <h3 className={style.title}>{name}</h3>
                <ul>
                    {rangs.split('\n').map(item=><li className={style.rangs} key={uuidv4()}>{item}</li>)}
                </ul>
            </div>
        </li>
    );
};
PresidiumItem.propTypes={
    content: PropTypes.object
}
export default PresidiumItem;
