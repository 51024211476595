// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefendersOfMoscow_memorial__Siuko {\n  padding: 0 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-width: 1200px;\n  margin: 0 auto;\n}\n.DefendersOfMoscow_memorial__Siuko img {\n  height: auto;\n  max-width: 500px;\n  width: 100%;\n  padding: 20px;\n}\n\n.DefendersOfMoscow_title__qzCPV {\n  margin: 30px 0;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/DefendersOfMoscow/DefendersOfMoscow.module.sass"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;AACF;AAAE;EACE,YAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;AAEJ;;AADA;EACE,cAAA;EACA,kBAAA;AAIF","sourcesContent":[".memorial\n  padding: 0 10px\n  display: flex\n  flex-direction: column\n  align-items: center\n  max-width: 1200px\n  margin: 0 auto\n  img\n    height: auto\n    max-width: 500px\n    width: 100%\n    padding: 20px\n.title\n  margin: 30px 0\n  text-align: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"memorial": "DefendersOfMoscow_memorial__Siuko",
	"title": "DefendersOfMoscow_title__qzCPV"
};
export default ___CSS_LOADER_EXPORT___;
