// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Preloader_container__D58Re {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.Preloader_container__D58Re img {\n  max-width: 200px;\n  height: auto;\n}\n@media (max-width: 1023px) {\n  .Preloader_container__D58Re img {\n    max-width: 100px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Preloader/Preloader.module.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,gBAAA;EACA,YAAA;AAEJ;AADI;EAHF;IAII,gBAAA;EAIJ;AACF","sourcesContent":[".container\n  width: 100%\n  display: flex\n  justify-content: center\n  align-items: center\n  img\n    max-width: 200px\n    height: auto\n    @media (max-width: 1023px)\n      max-width: 100px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Preloader_container__D58Re"
};
export default ___CSS_LOADER_EXPORT___;
