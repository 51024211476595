// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Thanks_container__4PGvu {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n}\n.Thanks_container__4PGvu img {\n  max-width: 300px;\n}\n\n.Thanks_title__eNLQy {\n  text-align: center;\n  text-transform: uppercase;\n  font-size: 30px;\n}", "",{"version":3,"sources":["webpack://./src/components/Thanks/Thanks.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AAAE;EACE,gBAAA;AAEJ;;AADA;EACE,kBAAA;EACA,yBAAA;EACA,eAAA;AAIF","sourcesContent":[".container\n  display: flex\n  flex-direction: column\n  align-items: center\n  position: relative\n  img\n    max-width: 300px\n.title\n  text-align: center\n  text-transform: uppercase\n  font-size: 30px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Thanks_container__4PGvu",
	"title": "Thanks_title__eNLQy"
};
export default ___CSS_LOADER_EXPORT___;
