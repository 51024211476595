// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MedalsList_subtitle__M4wvc {\n  font-size: 20px;\n  margin: 20px 0;\n  text-align: center;\n}\n\n.MedalsList_slugList__8LgHf {\n  display: grid;\n  grid-template-columns: repeat(4, auto);\n  justify-items: center;\n  align-items: flex-end;\n  gap: 20px 20px;\n}\n\n@media (max-width: 768px) {\n  .MedalsList_slugList__8LgHf {\n    grid-template-columns: repeat(1, auto);\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/MedalsList/MedalsList.module.sass"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAAA;EACE,aAAA;EACA,sCAAA;EACA,qBAAA;EACA,qBAAA;EACA,cAAA;AAGF;;AADA;EACE;IACE,sCAAA;EAIF;AACF","sourcesContent":[".subtitle\n  font-size: 20px\n  margin: 20px 0\n  text-align: center\n.slugList\n  display: grid\n  grid-template-columns: repeat(4, auto)\n  justify-items: center\n  align-items: flex-end\n  gap: 20px 20px\n\n@media(max-width: 768px)\n  .slugList\n    grid-template-columns: repeat(1, auto)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "MedalsList_subtitle__M4wvc",
	"slugList": "MedalsList_slugList__8LgHf"
};
export default ___CSS_LOADER_EXPORT___;
