import React from 'react';
import email from '../../assets/image/email.webp'
import style from './Thanks.module.sass'
import ButtonBlue from "../ButtonBlue/ButtonBlue";
const Thanks = () => {
    return (
        <div className={style.container}>
            <h2 className={style.title}>Спасибо что подписались на наши обновления</h2>
            <img src={email} alt=""/>
            <ButtonBlue title={'На главную'} link={'/'} specStyle={{marginTop: '30px'}}/>
        </div>
    );
};

export default Thanks;
