// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonBlue_blueBtn__wr9t3 {\n  color: white;\n  background-color: #66b5f1;\n  border: none;\n  padding: 16px 34px;\n  outline: none;\n  border-radius: 10px;\n  font-size: 30px;\n}\n@media (max-width: 769px) {\n  .ButtonBlue_blueBtn__wr9t3 {\n    font-size: 4.0625vw;\n    padding: 2.5vw 4.375vw;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/ButtonBlue/ButtonBlue.module.sass"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAAF;AACE;EARF;IASI,mBAAA;IACA,sBAAA;EAEF;AACF","sourcesContent":["@use 'sass:math'\n.blueBtn\n  color: white\n  background-color: #66b5f1\n  border: none\n  padding: 16px 34px\n  outline: none\n  border-radius: 10px\n  font-size: 30px\n  @media (max-width: 769px)\n    font-size: 13*math.div(100vw,320)\n    padding:  8*math.div(100vw,320) 14*math.div(100vw,320)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blueBtn": "ButtonBlue_blueBtn__wr9t3"
};
export default ___CSS_LOADER_EXPORT___;
