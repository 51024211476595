import React from 'react';
import style from './HistoryCreate.module.sass'
import oneImg from '../../assets/image/history/dolgih-vladimir.webp'
import twoImg from '../../assets/image/history/загруженное.webp'
import threeImg from '../../assets/image/history/Lenino-Snegirevskij-voenno-istoricheskij-muzej-i-memorial-pamyati-1100x687.webp'
import fourImg from '../../assets/image/history/u76nx4TQQbUFpInMa7uj6CS0J5H7Nc.webp'
import fiveImg from '../../assets/image/history/o-48525.webp'
import sixImg from '../../assets/image/history/1647618252_33-pibig-info-p-krasivie-mesta-v-tuve-priroda-priroda-kras-34.webp'
import sevenImg from '../../assets/image/history/original_photo-thumb_1920.webp'
import ScrollBtn from "../../components/ScrollBtn/ScrollBtn";

const HistoryCreate = () => {
    return (
        <section className={style.container}>
            <h1 className={style.title}>О работе Красноярского Землячества в Москве</h1>
            <p style={{fontStyle: 'italic', fontFamily: 'Roboto-Medium'}}>25 лет назад 24 апреля 1997 года состоялась учредительное собрание Общества «Красноярское Землячество».</p>
            <br/>
            <p>Проходило оно в помещении корпорации «Нечерноземагропромстрой», возглавлял которую наш земляк Леонид Яковлевич Иванов.</p>
            <img className={style.img} src={oneImg} alt="img"/>
            <p>Трудно переоценить роль основателей Землячества: Председателем оргкомитета  и бессменным руководителем Правления был В.И. Долгих.</p>
            <img className={style.img} src={twoImg} alt="img"/>
            <p>В январе 2021 года в связи с уходом из жизни В.И. Долгих полномочия председателя Правления Красноярского Землячества переданы на два года Г.И. Пашкову.</p>
            <br/>
            <p>В середине переломных 90-х годов многие выходцы из Красноярского края, живущие в Москве, почувствовали острую проблему в консолидации.</p>
            <br/>
            <p>Им была не безразлична судьба родного Красноярья, в развитие и расцвет которого они вложили свой многолетний труд. Беспокойство за судьбу уже созданного потенциала и за дальнейшую жизнь края, за своих земляков, стремление влиять на их повседневные дела объединило людей с московской пропиской, но сердцем оставшихся в родной Сибири.</p>
            <br/>
            <p>Организаторами Землячества стали академик Александр Сергеевич Исаев, бывший председатель Красноярского Совнархоза Василий Николаевич Ксинтарис, бывший директор Норильского горно-металлургического комбината, Герой Социалистического труда Борис Иванович Колесников, дважды олимпийский чемпион Иван Ярыгин, народная артистка Людмила Коркина, бывшие известные руководители края – Павел Стефанович Федирко, Полина Георгиевна Макеева, Леонид Георгиевич Сизов, Нина Прокопьевна Силкова, Владимир Николаевич Семенов, Владимир Иванович Малышков, и многие другие.</p>
            <br/>
            <p>Первые годы Землячество работало в здании корпорации МИРП, за что спасибо руководителю вышеупомянутой организации Вадиму Федоровичу Николайчуку. Он и сегодня помогает в работе Землячества.</p>
            <br/>
            <p>Руководство работой Землячества осуществляет избранное общим собранием Правление. Ежегодно Правлением разрабатывается план работы. И ветераны продолжают оставаться главной силой в этой работе.</p>
            <br/>
            <p>Поэтому Правление старается постоянно уделять внимание и проявлять заботу об участниках Великой Отечественной войны и трудового фронта.</p>
            <br/>
            <p>Ежегодно мы проводим встречи с участниками и ветеранами войны и труда с вручением им поздравлений и, пусть немного, но оказываем материальную помощь.</p>
            <img className={style.img} src={threeImg} alt="img"/>
            <p>Регулярными - 2 раза в год -  стали выезды к месту рубежа боевой славы на 42 км Волоколамского шоссе к памятнику-мемориалу «Войнам-сибирякам-защитникам Москвы». Памятник стал тем делом, которое объединило все сибирских землячества.</p>
            <br/>
            <p>Вместе с другими сибирскими землячествами у памятника мы участвуем в различных культурных программах, в проведении митингов, возлагаем цветы и венки к памятным датам.</p>
            <br/>
            <p>Надо сказать, что члены нашего Землячества охотно откликаются на эти мероприятия. Теперь уже не только члены сибирских, но и других землячеств России участвуют в этих выездах. И что очень приятно, в последние годы много молодежи.</p>
            <br/>
            <p>Продолжаем мы шефство и над школой в селе Чуприяновка, которая с 2005 года носит имя 17-ой Красноярской гвардейской дивизии (Постановление Калининского района Тверской области, 21 апреля 2005 г.). В селе, на месте захоронения красноярских воинов воздвигнут памятник. Ежегодно принимаем в Москве 40 школьников из с. Чуприяновка, они побывали в музее «Царицыно», на «Мосфильме», в художественной галерее Александра Шилова, в музее космонавтики, планетарии, в аквапарке на ВДНХ, а также побывали и в Президентском полку.</p>
            <br/>
            <p>Школе передано большое количество учебной и художественной литературы, помогаем мы и в оформлении и пополнении материалами школьного музей боевой Славы. Ребята целый год участвуют в соревновании за право поехать в Москву.</p>
            <br/>
            <p>Летом 24 апреля 2014 года у памятника 17-ой красноярской гвардейской дивизии в с.Чуприяновка, Тверской области были посажены два кедра, привезенные из Ермаковского района Красноярского края, как весточка с родной земли. Это была просьба ветеранов войны Чуприяновки. За саженцами с любовью ухаживают школьники.</p>
            <img className={style.img} src={fourImg} alt="img"/>
            <p>Вместе с делегациями сибирских землячеств ежегодно 14 августа наша делегация выезжает в Тверскую область в с. Плоское под городом Белым, где захоронено 22,5 тысячи воинов сибиряков 5-го сибирского добровольческого корпуса.</p>
            <br/>
            <p>Вместе с делегациями сибирских землячеств ежегодно 14 августа наша делегация выезжает в Тверскую область в с. Плоское под городом Белым, где захоронено 22,5 тысячи воинов сибиряков 5-го сибирского добровольческого корпуса.</p>
            <br/>
            <p>В 2020 году вышла  книга воспоминаний бывшего секретаря ЦК КПСС, дважды героя социалистического труда, председателя Красноярского Землячества в Москве В.И.Долгих «Дорогой созидания», книга охватывает почти вековую историю нашего государства.</p>
            <br/>
            <p>Вышла книга участника Великой отечественной войны Игоря Григорьевича  Гребцова «Война меня не отпускает». Как он ее охарактеризовал – « О моем седом уходящем поколении».</p>
            <br/>
            <p>Книга М.Важнова «Судьба», рассказывающая о жизни и судьбе Владимира Зосимовича Матвеева- первого начальника строительства Норильского горно- металлургического комбината в 1935-1938 гг.</p>
            <br/>
            <p>Давно разрабатываемая Ларисой Пронниковой тема норильской культуры пополнилась двумя книгами. Театральное досье «Дальше…Дальше…Дальше» посвящено Норильскому драматическому театру. А всего коллективом норильчан под ее редакцией подготовлено и выпущено 10 книг.
            </p>
            <br/>
                <p>Книга «70 шагов во времени», изданная этим коллективом, получила диплом  конкурса объявленного Правительством г. Москвы по номинации «Лучшая книга, изданная Землячеством по культурной деятельности». Каждая из этих книг проходила презентацию в Москве, Санкт-Петербурге, Красноярске, Норильске, Нижнем Новгороде.</p>
                <br/>
                <p>По-прежнему мы дружим с газетой «Красноярский рабочий», постоянно выписываем ее 30 членам Землячества и каждый член землячества может опубликовать в газете свои материалы.  Мы благодарны главному редактору Владимиру Евгеньевичу Павловскому за понимание и помощь.</p>
                <br/>
                <p>Недавно Правление приняло Постановление- поддержать предложение Красноярского отделения географического общества России о создании в городе Красноярске музея «Освоение русского севера». Думаю, нам надо включиться в эту работу. Мы могли бы оказать существенную помощь.</p>
                <br/>
                <p>Уже несколько лет мы дружим с руководством Президентского полка и военнослужащими, призванными в Президентский полк из нашего края. Теперь мы не только встречаемся с ребятами, но и бываем на ритуале- принятия присяги. У нас есть Соглашение о совместных делах с руководством полка.</p>
                <br/>
                <p>Мне приятно сообщить, что многие члены нашего Землячества занимают в Москве активную жизненную позицию.  Среди них хочу назвать Александра Александровича Бабенко-почетного Академика Академии архитектуры и строительных наук России, Болотова Виктора Александровича-члена корреспондента Академии образования, Тощенко Жана Терентьевича-члена корреспондента Российской Академии наук., Наталью Чеховскую и Василия Полунина - Народных артистов России, Людмилу Коркину – Народную артистку России, Игоря Григорьевича Гребцова - члена союза Писателей, участника Великой отечественной войны и многих других.</p>
                <br/>
                <p>Дважды делегация Землячества, благодаря помощи Евгения Ивановича Ветрова, члена Правления побывала в родном городе. К сожалению, традиция эта не была продолжена.</p>
                <br/>
                <p>Особенно хочется отметить работу Анатолия Петровича Назейкина, благодаря которому появился памятник Почтальону в г. Ярославле. Это единственный памятник в России. А ведь почтальоны играли очень важную роль в Великой отечественной войне.</p>
                <img className={style.img} src={fiveImg} alt="img"/>
                <br/>
                <p>Одной из особенностей работы Землячества является взаимодействие с Представительством Красноярского края при Правительстве РФ.</p>
                <br/>
                <p>Представительство во главе с Вольфом Андреем Карловичем заботится о земляках-красноярцах, помогает Землячеству жить и работать. Активно сотрудничают с Землячеством и другие работники Представительства. Особая благодарность земляков Юлии Карасевой, заместителю руководителя Представительства. Без участия работников Представительства не обходится ни одно сколь-нибудь значимое мероприятие.</p>
                <br/>
                <p>У Землячества сложились деловые отношения с Департаментом Национальной политики и межрегиональных связей Правительства Москвы. Мы принимаем участие во всех мероприятиях, проводимых Департаментом.</p>
                <br/>
                <p>Хорошие контакты у нас и с другими Землячествами - Вологодским, Томским, Западно-Сибирским, Коми, Иркутским и другими. Нас многое объединяет, мы бываем на отчетно-выборных собраниях, учимся друг у друга, советуемся с друг другом.</p>
                <br/>
                <p>Сибиряки - народ особый. Кроме смелости, трудолюбия, открытости и других достойных черт характера, они ценят чувство локтя, внимание товарищей.</p>
                <br/>
                <p>Мы стараемся поздравлять членов Общества с днем рождения, не оставляем без внимания юбилейные даты наших товарищей. В связи со столетием со дня рождения Народного артиста, Героя Социалистического труда М.С.Годенко, Правление устроило встречу с бывшими солистами ансамбля, живущими в Москве.</p>
                <br/>
                <p>В послужном списке Землячества немало славных, добрых, достойных дел, которые помогают красноярцам, живущим в Москве, чувствовать себя частицей нашего края.</p>
                <img className={style.img} src={sixImg} alt="img"/>
                <img className={style.img} src={sevenImg} alt="img"/>
                <br/>
                <p>Заместитель Председателя РОО-Общество «Красноярское землячество»</p>
                <p> Заместитель Председателя Правления  Н.П.Силкова</p>
            <ScrollBtn/>
        </section>
    );
};

export default HistoryCreate;
