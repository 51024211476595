import React, {useEffect} from 'react';
import coat from '../../assets/image/gerb_krasnoyarskogo_kraya.webp'
import background from '../../assets/image/history/a618573b-0551-49bb-8596-ee8a0c8b4b26.webp'
import nature from '../../assets/image/history/1647618252_33-pibig-info-p-krasivie-mesta-v-tuve-priroda-priroda-kras-34.webp'
import style from './Home.module.sass'
import ButtonBlue from "../../components/ButtonBlue/ButtonBlue";
import NewsList from "../../components/NewsList/NewsList";

const Home = () => {
    useEffect(()=>{
        window.scrollTo({
            left:0,
            top: 0,
        })
    })

    const styleBtn = {
        position: 'absolute',
        bottom: '10%'
    }
    const styleHomeBtn = {
        alignSelf: 'center',
        margin: '40px 0'
    }
    return (
        <>
            <article className={style.coat}>
                <img src={coat} alt="coat of arms"/>
                <h1>Красноярское землячество</h1>
                <p>в г. Москве</p>
            </article>
            <article className={style.historyBox}>
                <div className="container">
                    <div className={style.container}>
                        <img src={background} alt="Dolgih Vladimir"/>
                        <ButtonBlue specStyle={styleBtn} title={'История создания'} link={'/history'}/>
                    </div>
                </div>
            </article>
            <section className='container'>
                <div className={style.containerNews}>
                    <h2 className={style.newsTitle}>Новости</h2>
                    <NewsList count={2} section={'home'}/>
                    <ButtonBlue specStyle={styleHomeBtn} link={'/news'} title={'Все новости'}/>
                </div>
            </section>
            <article className={style.historyBox}>
                <div className="container">
                    <div className={style.container}>
                        <img src={nature} alt="nature"/>
                        <ButtonBlue specStyle={styleBtn} title={'О Красноярском крае'} link={'/about-us'}/>
                    </div>
                </div>
            </article>
        </>
    );
};

export default Home;
